import React, { useEffect, useState } from "react";
import AppLayout from "../../components/layouts/app-layout";
import PageHeader from "../../components/global-components/page-header";
import PropertyBox from "../../components/section-components/property-box";
import NoData from "../../components/global-components/no-data";
import { Pagination } from "@mui/material";
import LoadingData from "../../components/global-components/loading-data";
import { getPublishedUserProperties } from "../../api/propertyApi";
import {getWLProfile} from "../../api/userApi";
import { useAuth } from "../../helpers/context";
import { useParams } from "react-router-dom";
import SearchMapSection from "../../components/section-components/search-map";
import { useTranslation } from "react-i18next";

export default function Properties() {
  const [properties, setProperties] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [metaPage, setMetaPage] = useState("1");
  const [loadingProperties, setLoadingProperties] = useState(true);
  const { vendor } = useAuth();
  let { lat = "24.716199523004914", long = "46.671776478222675", sort = "createdAt", zoom = "11", listedFor = "2", propertyType = "all", page = "1" } = useParams();
  const [hideNavbar, setHideNavbar] = useState(false);
  const [wlPlan, setWLPlan] = useState(undefined);
  const [isTryWlMode, setIsTryWlMode] = useState("0");
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  
  useEffect(() => {
    if (vendor)
      getWLProfile({
        userName: vendor,
        language: i18n.language === 'ar' ? '0' : '1',
      }).then((_userInfo) => {
        if (_userInfo.resCode === 0)
          setWLPlan(_userInfo.response.wlUser.planId);
        setIsTryWlMode(_userInfo.response.wlUser.isTryWlMode);
      });
    
  }, [i18n, vendor]);

  useEffect(() => {
    if (vendor && wlPlan && !['6', '7', '8', '9'].includes(wlPlan)) {
      getPublishedUserProperties({ page: metaPage, vendorName: vendor }).then((res) => {
        if (res.resCode === 0) {
          setProperties(res.response.propertyArray);
          setTotalCount(res.response.totalCount);
        }
        setLoadingProperties(false);
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      }).catch(e => setLoadingProperties(false));
    }
  }, [vendor, metaPage, wlPlan]);

  const handlePageChanged = (e, newPage) => {
    if (newPage.toString() !== metaPage) {
      setProperties([]);
      setLoadingProperties(true);
      setMetaPage(newPage.toString());
    }
  }

  return (
    <AppLayout withFoote={!['6', '7', '8', '9'].includes(wlPlan)} pageTitle={t("OurAds")} withoutNav={hideNavbar}>
      {['6', '7', '8', '9'].includes(wlPlan) || isTryWlMode === "1" ? (
        <SearchMapSection
          propertyType={propertyType}
          listedFor={listedFor}
          lat={lat}
          long={long}
          sort={sort}
          zoom={zoom}
          page={page}
          setHideNavbar={setHideNavbar}
        />
      ) : wlPlan && (
        <>
          <PageHeader name={t("OurAds")} />
          <div className='featured-props'>
            <div className='row justify-content-center pd-top-60'>
              <div className='row align-items-center justify-content-end col-12 pd-bottom-60'>
                {loadingProperties ? <LoadingData /> : (
                  properties?.filter(item => item?.additionalInfo?.filter(i => i.key === '34')?.[0]?.valueText !== 'الهيئة العامة للعقار')?.length > 0
                    ? properties?.filter(item => item?.additionalInfo?.filter(i => i.key === '34')?.[0]?.valueText !== 'الهيئة العامة للعقار')?.map((item, i) =>
                    (
                      <div
                        key={i}
                        data-aos='flip-left'
                        className='col-xl-3 col-lg-4 col-md-6 col-sm-6'
                        style={{ cursor: "pointer" }}>
                        <PropertyBox
                          propertey={item}
                          isEdit={false}
                          isFav={false}
                        />
                      </div>
                    )) : <NoData msg={t("ThereAreNoADs")} />
                )}
              </div>
              {totalCount > 50 && !loadingProperties && (
                <div className='col-12 justify-content-center d-flex mb-4 pb-5 pb-md-0'>
                  <Pagination
                    sx={{ "& .MuiPagination-ul": { flexDirection: "row-reverse" } }}
                    count={parseInt(Math.ceil(totalCount / 50))}
                    page={Number(metaPage)}
                    onChange={handlePageChanged}
                    color='primary'
                    siblingCount={0}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </AppLayout>
  );
}
