import React, { useEffect, useMemo, useState } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Loadable from "react-loadable";
import { ToastContainer } from "react-toastify";
import "./i18nextInit";
import AOS from "aos";
import "aos/dist/aos.css";
import "swiper/css/bundle";
import "moment/locale/ar";
import moment from "moment";
import { ThemeProvider } from "@mui/material/styles";
import { customTheme, AppContext } from "./helpers/context";
import CssBaseline from "@mui/material/CssBaseline";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import "sweetalert2/src/sweetalert2.scss";
import "./assets/css/app.min.css";
import CookieConsentBar from "./components/global-components/cookie-consent";
import OnlineDetection from "./components/global-components/online-detection";
import HomeV1 from "./components/home-v1";
import PropertyDetails from "./scenes/shared/properties/property-details";
import About from "./scenes/shared/about";
import Terms from "./scenes/shared/terms";
import Registraion from "./components/registration";
import OTP from "./components/section-components/registration/OTPPage";
import SetPassword from "./components/section-components/registration/SetPasswordPage";
import Success from "./components/section-components/registration/SuccessPage";
import CompanyManagerIDValidation from "./components/section-components/registration/CompanyManagerIDValidationPage";
import CompanyManagerOTPValidation from "./components/section-components/registration/CompanyManagerOTPValidationPage.js";
import CompanyLocationVerfication from "./components/section-components/registration/CompanyLocationVerficationPage.js";
import BrokerOTPValidation from "./components/section-components/registration/BrokerOTPPage";
import SuccessVerfication from "./components/section-components/registration/SuccessVerficationPage";
import Error from "./scenes/shared/404";
import ProjectDetails from "./components/projectDetails";
import Contact from "./scenes/shared/contact";
import SearchMap from "./scenes/amakkn/search-map";
import REGAAddNew from "./scenes/shared/rega-properties/add-property";
import REGAEditProperty from "./scenes/shared/rega-properties/edit-property";
import REGAAddNewStep3 from "./scenes/shared/rega-properties/add-property-step3";
import REGAAddNew2 from "./scenes/shared/rega-properties2/add-property";
import REGAEditProperty2 from "./scenes/shared/rega-properties2/edit-property";
import REGAAddNew2Step3 from "./scenes/shared/rega-properties2/add-property-step3";
// import AddNew from "./scenes/shared/properties/add-property";
// import EditProperty from "./scenes/shared/properties/edit-property";
// import AddNewStep3 from "./scenes/shared/properties/add-property-step3";
import MyFavorite from "./scenes/amakkn/my-favorites";
import UserProfile from "./components/user-profile.js";
import CompanyProfile from "./scenes/amakkn/company-profile.js";
import MyProfile from "./scenes/amakkn/profile";
import Projects from "./scenes/white-label/projects.js";
import Chat from "./components/Chats/Chats";
import CsChat from "./components/CSChats/CSChats";
import Ticket from "./components/Tickets/CSChats";
import ChangePhone from "./scenes/amakkn/profile/components/change-phone";
import OtpChangePhone from "./components/section-components/registration/otpChangePhone";
// import Verify from "./scenes/amakkn/verify";
import ChangePassword from "./scenes/amakkn/profile/components/change-password";
import Agents from "./scenes/amakkn/agents";
import AddAgent from "./scenes/amakkn/agents/add-agent";
import OTPAddAgent from "./components/section-components/registration/otpAddAgent";
import EditAgent from "./scenes/amakkn/agents/edit-agent";
import IAMLink from "./components/auth/callback";
import WhiteLabel from "./components/section-components/white-label/index.js";
import WhiteLabel2 from "./components/section-components/white-label/step2.js";
import Admin from "./components/admin/index.js";
import MyListing from "./scenes/shared/properties";
import UnAuthorized from "./scenes/shared/401";
import ChangeAgentPhone from "./scenes/amakkn/agents/change-agent-phone";
import Ejar from "./scenes/shared/ejar";
import ForgotPassword from "./scenes/shared/forgot-password";
import Comparison from "./scenes/amakkn/comparison";
import ComparisonList from "./scenes/amakkn/comparison-list";
import { saveCookie, getCookie, getVendorName } from "./helpers";
import EmailVerification from "./scenes/white-label/email-verification";
import Properties from "./scenes/white-label/properties";
import NewVisitingDetection from "./components/global-components/visiting-detection";
import PrivacyPolicy from "./scenes/shared/privacy-policy";
import { getDomainOwner, getWLProfile } from "./api/userApi";
import MySubscriptionPage from "./scenes/amakkn/my-subscription";
import PlanRegistrationPage from "./scenes/amakkn/plan-registration";
import SuccessRegistration from "./scenes/amakkn/plan-registration/success-registration";
import OTPAgent from "./components/section-components/registration/OTPAgent";
import AgentSetPassword from "./components/section-components/registration/AgentSetPasswordPage";
import OTPPassword from "./components/section-components/registration/OTPPassword";
import SetNewPassword from "./components/section-components/registration/SetNewPasswordPage";
import SuccessResetPassword from "./components/section-components/registration/SuccessResetPasswordPage";
import WLUsers from "./scenes/amakkn/wl-users";
import WLUserDetails from "./scenes/amakkn/wl-user-details";
import Blog from "./scenes/white-label/blog";
import News from "./scenes/white-label/news";
import {
  CLASSIC2_TEMPLATES,
  CLASSIC_TEMPLATES,
  DARK_TEMPLATES,
  DEFAULT_TEMPLATES,
  DEMO_VENDORS,
  MODERN2_TEMPLATES,
  MODERN_TEMPLATES,
} from "./constants";
import WLIntellectualPropertyRights from "./scenes/white-label/intellectual-property-rights.js";
import DeleteMyAccount from "./scenes/shared/delete-my-account.js";
import DeleteUsageInfo from "./scenes/shared/delete-usage-info.js";
import PromoCodes from "./scenes/shared/promo-codes.js";
import PropertyPapper from "./components/property-paper.js";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Verify2 from "./scenes/amakkn/verify2";


const container = document.getElementById("amakkn");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

// function Loading() {
//   const { vendor } = useAuth();
//   return <div>{vendor === "amakkn" ? <AmakknNavbar /> : <></>}</div>;
// }
// const HomeV1 = Loadable({
//   loader: () => import("./components/home-v1"),
//   loading: Loading,
// });

// const PropertyDetails = Loadable({
//   loader: () => import("./scenes/shared/properties/property-details"),
//   loading: Loading,
// });
// const REGAAddNew2 = Loadable({
//   loader: () => import("./scenes/shared/rega-properties2/add-property"),
//   loading: Loading,
// });
// const REGAAddNew = Loadable({
//   loader: () => import("./scenes/shared/rega-properties/add-property"),
//   loading: Loading,
// });
// const REGAAddNewStep3 = Loadable({
//   loader: () => import("./scenes/shared/rega-properties/add-property-step3"),
//   loading: Loading,
// });
// const REGAEditProperty2 = Loadable({
//   loader: () => import("./scenes/shared/rega-properties2/edit-property"),
//   loading: Loading,
// });
// const REGAEditProperty = Loadable({
//   loader: () => import("./scenes/shared/rega-properties/edit-property"),
//   loading: Loading,
// });

// const REGAAddNew2Step3 = Loadable({
//   loader: () => import("./scenes/shared/rega-properties2/add-property-step3"),
//   loading: Loading,
// });

// const PrivacyPolicy = Loadable({
//   loader: () => import("./scenes/shared/privacy-policy"),
//   loading: Loading,
// });
// const WLUsers = Loadable({
//   loader: () => import("./scenes/amakkn/wl-users"),
//   loading: Loading,
// });
// const SuccessResetPassword = Loadable({
//   loader: () => import("./components/section-components/registration/SuccessResetPasswordPage"),
//   loading: Loading,
// });
// const SetNewPassword = Loadable({
//   loader: () => import("./components/section-components/registration/SetNewPasswordPage"),
//   loading: Loading,
// });
// const OTPPassword = Loadable({
//   loader: () => import("./components/section-components/registration/OTPPassword"),
//   loading: Loading,
// });
// const Properties = Loadable({
//   loader: () => import("./scenes/white-label/properties"),
//   loading: Loading,
// });

// const EmailVerification = Loadable({
//   loader: () => import("./scenes/white-label/email-verification"),
//   loading: Loading,
// });

// const MySubscriptionPage = Loadable({
//   loader: () => import("./scenes/amakkn/my-subscription"),
//   loading: Loading,
// });

// const About = Loadable({
//   loader: () => import("./scenes/shared/about"),
//   loading: Loading,
// });
// const Terms = Loadable({
//   loader: () => import("./scenes/shared/terms"),
//   loading: Loading,
// });

// const Registraion = Loadable({
//   loader: () => import("./components/registration"),
//   loading: Loading,
// });
// const OTP = Loadable({
//   loader: () => import("./components/section-components/registration/OTPPage"),
//   loading: Loading,
// });
// const SetPassword = Loadable({
//   loader: () =>
//     import("./components/section-components/registration/SetPasswordPage"),
//   loading: Loading,
// });
// const Success = Loadable({
//   loader: () =>
//     import("./components/section-components/registration/SuccessPage"),
//   loading: Loading,
// });
// const CompanyManagerIDValidation = Loadable({
//   loader: () =>
//     import(
//       "./components/section-components/registration/CompanyManagerIDValidationPage"
//     ),
//   loading: Loading,
// });
// const CompanyManagerOTPValidation = Loadable({
//   loader: () =>
//     import(
//       "./components/section-components/registration/CompanyManagerOTPValidationPage.js"
//     ),
//   loading: Loading,
// });
// const CompanyLocationVerfication = Loadable({
//   loader: () =>
//     import(
//       "./components/section-components/registration/CompanyLocationVerficationPage.js"
//     ),
//   loading: Loading,
// });
// const BrokerOTPValidation = Loadable({
//   loader: () =>
//     import("./components/section-components/registration/BrokerOTPPage"),
//   loading: Loading,
// });
// const SuccessVerfication = Loadable({
//   loader: () =>
//     import(
//       "./components/section-components/registration/SuccessVerficationPage"
//     ),
//   loading: Loading,
// });
// // const Error = Loadable({
// //   loader: () => import("./scenes/shared/404"),
// //   loading: Loading,
// // });
// const News = Loadable({
//   loader: () => import("./scenes/white-label/news"),
//   loading: Loading,
// });
// const ProjectDetails = Loadable({
//   loader: () => import("./components/projectDetails"),
//   loading: Loading,
// });
// const Contact = Loadable({
//   loader: () => import("./scenes/shared/contact"),
//   loading: Loading,
// });
// const SearchMap = Loadable({
//   loader: () => import("./scenes/amakkn/search-map"),
//   loading: Loading,
// });
// // const AddNew = Loadable({
// //   loader: () => import("./scenes/shared/properties/add-property"),
// //   loading: Loading,
// // });
// // const EditProperty = Loadable({
// //   loader: () => import("./scenes/shared/properties/edit-property"),
// //   loading: Loading,
// // });
// // const AddNewStep3 = Loadable({
// //   loader: () => import("./scenes/shared/properties/add-property-step3"),
// //   loading: Loading,
// // });
// const MyFavorite = Loadable({
//   loader: () => import("./scenes/amakkn/my-favorites"),
//   loading: Loading,
// });
// const UserProfile = Loadable({
//   loader: () => import("./components/user-profile.js"),
//   loading: Loading,
// });
// const CompanyProfile = Loadable({
//   loader: () => import("./scenes/amakkn/company-profile.js"),
//   loading: Loading,
// });
// const MyProfile = Loadable({
//   loader: () => import("./scenes/amakkn/profile"),
//   loading: Loading,
// });
// const Projects = Loadable({
//   loader: () => import("./scenes/white-label/projects.js"),
//   loading: Loading,
// });
// // const Chat = Loadable({
// //   loader: () => import("./components/Chats/Chats"),
// //   loading: Loading,
// // });
// // const CsChat = Loadable({
// //   loader: () => import("./components/CSChats/CSChats"),
// //   loading: Loading,
// // });
// // const Ticket = Loadable({
// //   loader: () => import("./components/Tickets/CSChats"),
// //   loading: Loading,
// // });
// const ChangePhone = Loadable({
//   loader: () => import("./scenes/amakkn/profile/components/change-phone"),
//   loading: Loading,
// });
// const OtpChangePhone = Loadable({
//   loader: () =>
//     import("./components/section-components/registration/otpChangePhone"),
//   loading: Loading,
// });
// const Verify = Loadable({
//   loader: () => import("./scenes/amakkn/verify"),
//   loading: Loading,
// });
// const Verify2 = Loadable({
//   loader: () => import("./scenes/amakkn/verify2"),
//   loading: Loading,
// });
// const ChangePassword = Loadable({
//   loader: () => import("./scenes/amakkn/profile/components/change-password"),
//   loading: Loading,
// });
// const Agents = Loadable({
//   loader: () => import("./scenes/amakkn/agents"),
//   loading: Loading,
// });
// const AddAgent = Loadable({
//   loader: () => import("./scenes/amakkn/agents/add-agent"),
//   loading: Loading,
// });
// const OTPAddAgent = Loadable({
//   loader: () =>
//     import("./components/section-components/registration/otpAddAgent"),
//   loading: Loading,
// });
// const EditAgent = Loadable({
//   loader: () => import("./scenes/amakkn/agents/edit-agent"),
//   loading: Loading,
// });
// const ForgotPassword = Loadable({
//   loader: () => import("./scenes/shared/forgot-password"),
//   loading: Loading,
// });

// // const VerifiedCompanies = Loadable({
// //   loader: () => import("./scenes/amakkn/verified-companies"),
// //   loading: Loading,
// // });
// // const IAMLink = Loadable({
// //   loader: () => import("./components/auth/callback"),
// //   loading: Loading,
// // });
// // const CheckAdvertiserNumber = Loadable({
// //   loader: () => import("./scenes/amakkn/verify/components/check-ad-number"),
// //   loading: Loading,
// // });

// const WhiteLabel = Loadable({
//   loader: () => import("./components/section-components/white-label/index.js"),
//   loading: Loading,
// });
// const WhiteLabel2 = Loadable({
//   loader: () => import("./components/section-components/white-label/step2.js"),
//   loading: Loading,
// });
// const Admin = Loadable({
//   loader: () => import("./components/admin/index.js"),
//   loading: Loading,
// });
// const MyListing = Loadable({
//   loader: () => import("./scenes/shared/properties"),
//   loading: Loading,
// });
// // const UnAuthorized = Loadable({
// //   loader: () => import("./scenes/shared/401"),
// //   loading: Loading,
// // });
// const WLIntellectualPropertyRights = Loadable({
//   loader: () => import("./scenes/white-label/intellectual-property-rights"),
//   loading: Loading,
// });
// const DeleteMyAccount = Loadable({
//   loader: () => import("./scenes/shared/delete-my-account"),
//   loading: Loading,
// });
// const DeleteUsageInfo = Loadable({
//   loader: () => import("./scenes/shared/delete-usage-info"),
//   loading: Loading,
// });
// const PromoCodes = Loadable({
//   loader: () => import("./scenes/shared/promo-codes"),
//   loading: Loading,
// });
// const Comparison = Loadable({
//   loader: () => import("./scenes/amakkn/comparison"),
//   loading: Loading,
// });
// const ComparisonList = Loadable({
//   loader: () => import("./scenes/amakkn/comparison-list"),
//   loading: Loading,
// });
// const ChangeAgentPhone = Loadable({
//   loader: () => import("./scenes/amakkn/agents/change-agent-phone"),
//   loading: Loading,
// });
// const Ejar = Loadable({
//   loader: () => import("./scenes/shared/ejar"),
//   loading: Loading,
// });
// const WLUserDetails = Loadable({
//   loader: () => import("./scenes/amakkn/wl-user-details"),
//   loading: Loading,
// });
// const Blog = Loadable({
//   loader: () => import("./scenes/white-label/blog"),
//   loading: Loading,
// });
// const AgentSetPassword = Loadable({
//   loader: () => import("./components/section-components/registration/AgentSetPasswordPage"),
//   loading: Loading,
// });
// const OTPAgent = Loadable({
//   loader: () => import("./components/section-components/registration/OTPAgent"),
//   loading: Loading,
// });
// const SuccessRegistration = Loadable({
//   loader: () => import("./scenes/amakkn/plan-registration/success-registration"),
//   loading: Loading,
// });
// const PlanRegistrationPage = Loadable({
//   loader: () => import("./scenes/amakkn/plan-registration"),
//   loading: Loading,
// });


const Root = () => {
  const [vendor, setVendor2] = useState(getCookie("Vendor"));
  const style = document.getElementById("main-stylesheet");
  const templateStylesheet = document.getElementById("template-stylesheet");
  const panelStylesheet = document.getElementById("panel-stylesheet");

  AOS.init();

  const [theme, setTheme] = useState(customTheme());
  const [token, setToken2] = useState(getCookie("token"));
  const [UserId, setUserId2] = useState(getCookie("UserId"));
  const [UserType, setUserType2] = useState(getCookie("UserType"));
  const [AccountType, setAccountType2] = useState(getCookie("AccountType"));

  const [logo, setLogo] = useState("");
  const [currentPlan, setCurrentPlan2] = useState(getCookie("CurrentPlan"));
  const [currentDomain, setCurrentDomain2] = useState("");
  const [paymentData, setPaymentData2] = useState(getCookie("PaymentData"));
  const [planEndDate, setPlanEndDate2] = useState(getCookie("planEndDate"));
  const [unreadMessagesCount, setUnreadMessagesCount2] = useState(0);
  const [MenuData, setMenuData2] = useState(undefined);
  const [ShowMenuPhone, setShowMenuPhone2] = useState(undefined);
  const [iconPackage, setIconPackage2] = useState("1");
  const [templateId, setTemplateId2] = useState(0);
  const [loadingTemplate, setLoadingTemplate2] = useState(true);
  const [mapKey, setMapKey2] = useState(getCookie("MapKey"));
  const { i18n } = useTranslation();
  
  const setMapKey = (val) => {
    saveCookie("MapKey", val);
    setMapKey2(val);
  };

  const setLoadingTemplate = (val) => {
    setLoadingTemplate2(val);
  };

  const setTemplateId = (val) => {
    setTemplateId2(val);
  };

  const setUnreadMessagesCount = (val) => {
    setUnreadMessagesCount2(val);
  };
  const setPlanEndDate = (val) => {
    setPlanEndDate2(val);
  };
  const setVendor = (val) => {
    saveCookie("Vendor", val);
    setVendor2(val);
  };
  const setCurrentDomain = (val) => {
    setCurrentDomain2(val);
  };
  const setToken = (val) => {
    saveCookie("token", val);
    setToken2(val);
  };
  const setUserId = (val) => {
    saveCookie("UserId", val);
    setUserId2(val);
  };
  const setUserType = (val) => {
    setUserType2(val);
  };
  const setAccountType = (val) => {
    setAccountType2(val);
  };
  const setCurrentPlan = (val) => {
    setCurrentPlan2(val);
  };
  const setPaymentData = (val) => {
    saveCookie("PaymentData", val);
    setPaymentData2(val);
  };
  const setMenuData = (val) => {
    setMenuData2(val);
  };
  const setShowMenuPhone = (val) => {
    setShowMenuPhone2(val);
  };
  const setIconPackage = (val) => {
    setIconPackage2(val);
  };

  const value = useMemo(
    () => ({
      theme,
      setTheme,
      token,
      setToken,
      UserId,
      setUserId,
      UserType,
      setUserType,
      AccountType,
      setAccountType,
      logo,
      setLogo,
      currentPlan,
      setCurrentPlan,
      currentDomain,
      setCurrentDomain,
      paymentData,
      setPaymentData,
      vendor,
      setVendor,
      planEndDate,
      setPlanEndDate,
      unreadMessagesCount,
      setUnreadMessagesCount,
      MenuData,
      setMenuData,
      ShowMenuPhone,
      setShowMenuPhone,
      iconPackage,
      setIconPackage,
      templateId,
      setTemplateId,
      loadingTemplate,
      setLoadingTemplate,
      mapKey,
      setMapKey
    }),
    [
      theme,
      unreadMessagesCount,
      planEndDate,
      token,
      UserType,
      UserId,
      logo,
      currentPlan,
      AccountType,
      currentDomain,
      paymentData,
      vendor,
      MenuData,
      ShowMenuPhone,
      iconPackage,
      templateId,
      loadingTemplate,
      mapKey
    ]
  );

  // prevent manipulation of the vendor cookie
  useEffect(
    () => {
      if (vendor) {
        if (window.location.hostname.includes("inme.com.sa")) {
          setVendor("amakkn");
        } else {
          if (
            window.location.hostname !== "localhost" &&
            !window.location.hostname.includes("amakkn.")
          ) {
            getDomainOwner({
              domain:
                window.location.hostname.includes("www.")
                  ? window.location.hostname.substring(4)
                  : window.location.hostname,
            })
              .then((res) => {
                if (res.resCode === 0) {
                  let vendorName = res?.response?.userName;
                  if (vendorName !== vendor) {
                    // clear cookies
                    document.cookie.split(";").forEach(function (c) {
                      if (!c.includes("AmmaknConsent"))
                        document.cookie = c
                          .replace(/^ +/, "")
                          .replace(
                            /=.*/,
                            "=;expires=" + new Date().toUTCString() + ";path=/"
                          );
                    });
                    setVendor(vendorName);
                  }
                } else {
                  setLoadingTemplate(false);
                  let r = document.querySelector(":root");
                  r.style.setProperty("--main-color-one", "#243ead");
                  r.style.setProperty("--main-color-two", "#fff");
                  r.style.setProperty("--heading-color", "#333333");
                  r.style.setProperty("--paragraph-color", "#666666");
                  r.style.setProperty("--btn-color", "#fff");
                  r.style.setProperty("--body-font", "Amazon Ember");
                  r.style.setProperty("--body-font-size", "15px");
                }
               
              })
              .catch(() => { });
          } else {
            let vendorName = window.location.hostname.split(".")[0];
            if (vendorName === "localhost")
              vendorName = process.env.REACT_APP_LOCALHOST_VENDOR;
            else if (vendorName === "www")
              vendorName = window.location.hostname.split(".")[1];
            if (vendorName !== vendor) {
              // clear cookies
              document.cookie.split(";").forEach(function (c) {
                if (!c.includes("AmmaknConsent"))
                  document.cookie = c
                    .replace(/^ +/, "")
                    .replace(
                      /=.*/,
                      "=;expires=" + new Date().toUTCString() + ";path=/"
                    );
              });
              setVendor(vendorName);
            }
          }
        }
      }
    }, // eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (vendor === 'amakkn') {
      document.documentElement.setAttribute('lang', i18n.language === "ar" ? 'ar' : 'en');
      moment.locale(i18n.language === "ar" ? "ar-sa" : "en");
    } else {
      i18next.changeLanguage('ar');
      i18n.language = 'ar';
      i18n.options.lng = 'ar';
      localStorage?.setItem('i18nextLng', 'ar');
      document.documentElement.setAttribute('lang', 'ar');
      moment.locale("ar-sa");
    }
    
  }, // eslint-disable-next-line
    [i18n.language, vendor]);

  useEffect(() => {
    const gtmBody = document.body.querySelector('noscript#gtm-body'),
      gtmHead = document.head.querySelector('script#gtm-head'),
      analyticsHead = document.head.querySelector('script#analytics-head');

    if (vendor === 'amakkn') {
      if (gtmBody) {
        let el = document.createElement("iframe");
        el.width = 0;
        el.height = 0;
        el.src = 'https://www.googletagmanager.com/ns.html?id=GTM-PF4KX55';
        gtmBody.appendChild(el);
      }
      if (gtmHead) {
        gtmHead.innerHTML = ` (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", "GTM-PF4KX55");`
      }
    } else {
      getWLProfile({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then(res => {
        if (res.resCode === 0 && res.response.wlUser.gtm_container) {
          if (gtmBody) {
            let el = document.createElement("iframe");
            el.width = 0;
            el.height = 0;
            el.src = `https://www.googletagmanager.com/ns.html?id=${res.response.wlUser.gtm_container}`;
            gtmBody.appendChild(el);
          }
          if (gtmHead) {
            gtmHead.innerHTML = ` (function (w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != "dataLayer" ? "&l=" + l : "";
              j.async = true;
              j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, "script", "dataLayer", "${res.response.wlUser.gtm_container}");`
          }
        }
        if (res.resCode === 0 && res.response.wlUser.gtm_measurement_id) {
          if (analyticsHead) {
            analyticsHead.src = `https://www.googletagmanager.com/gtag/js?id=${res.response.wlUser.gtm_measurement_id}`;
            analyticsHead.innerHTML = `<script>
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
            
              gtag('config', '${res.response.wlUser.gtm_measurement_id}');
            </script>`
          }
        }
        if (res.resCode === 0) {
          const templateId = res.response.templateId;

          if (!panelStylesheet?.href && vendor !== "amakkn") {
            panelStylesheet.href = "/assets/css/panel.css";
          }
          if (!templateStylesheet?.href && vendor !== "amakkn") {
            if (templateId) {
              if (DEFAULT_TEMPLATES.includes(+templateId)) {
                document.body.classList.add("default-template");
                templateStylesheet.href = "/assets/css/panel.css";
              } else if (MODERN_TEMPLATES.includes(+templateId)) {
                document.body.classList.add("modern-template");
                templateStylesheet.href = "/assets/css/templates-3-4.css";
              } else if (DARK_TEMPLATES.includes(+templateId)) {
                document.body.classList.add("dark-template");
                templateStylesheet.href = "/assets/css/templates-5-6.css";
              } else if (CLASSIC_TEMPLATES.includes(+templateId)) {
                document.body.classList.add("classic-template");
                templateStylesheet.href = "/assets/css/templates-7-8.css";
              } else if (CLASSIC2_TEMPLATES.includes(+templateId)) {
                document.body.classList.add("classic2-template");
                templateStylesheet.href = "/assets/css/templates-9-10.css";
              } else if (MODERN2_TEMPLATES.includes(+templateId)) {
                document.body.classList.add("modern2-template");
                templateStylesheet.href = "/assets/css/templates-11-12.css";
              }
            } else {
              document.body.classList.add("default-template");
              templateStylesheet.href = "/assets/css/panel.css";
            }
           
            setTimeout(() => {
              setLoadingTemplate(false);
            }, 300);
          }
        }
      });
    }
  }, // eslint-disable-next-line
    [vendor, templateStylesheet, panelStylesheet]);

  const getVendor = async () => {
    let vendor = await getVendorName();
    setVendor(vendor);
  };
  useEffect(
    () => {
      if (!vendor) getVendor();
      if (vendor) {
        if (DEMO_VENDORS.includes(vendor)) {
          let r = document.querySelector(":root");
          const CSS = JSON.parse(localStorage.getItem(`palette-${vendor}`));
          if (CSS?.mainColorOne) {
            r.style.setProperty("--main-color-one", CSS.mainColorOne);
            r.style.setProperty("--main-color-two", CSS.mainColorTwo);
            r.style.setProperty("--heading-color", CSS.headingColor);
            r.style.setProperty("--paragraph-color", CSS.paragraphColor);
            r.style.setProperty("--btn-color", CSS.btnColor);
            r.style.setProperty("--body-font", CSS.bodyFont);
            r.style.setProperty("--body-font-size", CSS.bodyFontSize);
          } else {
            const link =
              "https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" +
              vendor +
              "/assets/css/colors.css?" +
              Math.random();
            style.href = link;
          }
        } else {
          const link =
            "https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" +
            vendor +
            "/assets/css/colors.css?" +
            Math.random();
          style.href = link;
        }

        let publicUrl = `https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/${vendor}/`;
        setLogo(publicUrl + "assets/img/Logo_black.png?" + Math.random());

        if (vendor !== "amakkn") {
          let linkico = document.querySelector("link[rel~='icon']");
          if (!linkico) {
            linkico = document.createElement("link");
            linkico.rel = "icon";
            document.getElementsByTagName("head")[0].appendChild(linkico);
          }
          linkico.href = publicUrl + "assets/img/Logo_black.png";
          let linkico2 = document.querySelector(
            "link[rel~='apple-touch-icon']"
          );
          if (!linkico2) {
            linkico2 = document.createElement("link");
            linkico2.rel = "apple-touch-icon";
            document.getElementsByTagName("head")[0].appendChild(linkico2);
          }
          linkico2.href = publicUrl + "assets/img/Logo_black.png";
        }

        const onPageLoad = () => {
          setTimeout(() => {
            const bodyFont = getComputedStyle(
              document.body,
              null
            ).getPropertyValue("--body-font");
            const mainColorOne = getComputedStyle(
              document.body,
              null
            ).getPropertyValue("--main-color-one");
            setTheme(customTheme(mainColorOne.trim(), bodyFont.trim()));
          }, 500);
        };
        if (document.readyState === "complete") {
          onPageLoad();
        } else {
          window.addEventListener("load", onPageLoad);
          return () => window.removeEventListener("load", onPageLoad);
        }
      }
    },
    // eslint-disable-next-line
    [vendor]
  );

  // useEffect(() => {
  //   if (!panelStylesheet?.href && vendor !== "amakkn") {
  //     panelStylesheet.href = "/assets/css/panel.css";
  //   }
  //   if (!templateStylesheet?.href && vendor !== "amakkn") {
  //     console.log({templateId})
  //     if (templateId) {
  //       if (DEFAULT_TEMPLATES.includes(+templateId)) {
  //         document.body.classList.add("default-template");
  //         templateStylesheet.href = "/assets/css/panel.css";
  //       } else if (MODERN_TEMPLATES.includes(+templateId)) {
  //         document.body.classList.add("modern-template");
  //         templateStylesheet.href = "/assets/css/templates-3-4.css";
  //       } else if (DARK_TEMPLATES.includes(+templateId)) {
  //         document.body.classList.add("dark-template");
  //         templateStylesheet.href = "/assets/css/templates-5-6.css";
  //       } else if (CLASSIC_TEMPLATES.includes(+templateId)) {
  //         document.body.classList.add("classic-template");
  //         templateStylesheet.href = "/assets/css/templates-7-8.css";
  //       } else if (CLASSIC2_TEMPLATES.includes(+templateId)) {
  //         document.body.classList.add("classic2-template");
  //         templateStylesheet.href = "/assets/css/templates-9-10.css";
  //       } else if (MODERN2_TEMPLATES.includes(+templateId)) {
  //         document.body.classList.add("modern2-template");
  //         templateStylesheet.href = "/assets/css/templates-11-12.css";
  //       }
  //     } else {
  //       document.body.classList.add("default-template");
  //       templateStylesheet.href = "/assets/css/panel.css";
  //     }
     
  //     setTimeout(() => {
  //       setLoadingTemplate(false);
  //     }, 300);
  //   }
  // }, [vendor, templateId, templateStylesheet, panelStylesheet]);

  return (
    <AppContext.Provider value={value}>
      <ThemeProvider theme={value.theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path='/' element={<HomeV1 />} />
            <Route path='/property-details/:id' element={<PropertyDetails />} />
            <Route path='/property-details' element={<PropertyDetails />} />
            <Route path='/about' element={<About />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/my-subscription' element={<MySubscriptionPage />} />
            <Route
              path='/my-subscription/:userId'
              element={<MySubscriptionPage />}
            />

            <Route
              path='/plan-registration'
              element={<PlanRegistrationPage />}
            />
            <Route
              path='/plan-registration/:id'
              element={<PlanRegistrationPage />}
            />
            <Route
              path='/plans-and-prices'
              element={<PlanRegistrationPage />}
            />
            <Route
              path='/plans-and-prices/:id'
              element={<PlanRegistrationPage />}
            />
            <Route
              path='/success-registration/:name/:wlName'
              element={<SuccessRegistration />}
            />
            <Route
              path='/success-registration/:name'
              element={<SuccessRegistration />}
            />

            <Route path='/terms-condition' element={<Terms />} />
            {/* <Route path='/advisor' element={<Advisor />} /> */}
            {/* <Route path='/pricing' element={<Pricing />} /> */}
            {/* <Route path='/user-list' element={<UserList />} /> */}
            <Route path='/registration' element={<Registraion />} />

            <Route
              path='/setpassword/:countryCode/:phone'
              element={<SetPassword />}
            />
            <Route
              path='/set-new-password/:countryCode/:phone'
              element={<SetNewPassword />}
            />

            <Route
              path='/agent-setpassword/:countryCode/:phone'
              element={<AgentSetPassword />}
            />

            <Route path='/success/:userType' element={<Success />} />
            <Route
              path='/success-reset-password'
              element={<SuccessResetPassword />}
            />
            <Route
              path='/successverfication'
              element={<SuccessVerfication />}
            />
            <Route
              path='/brokerotp/:nationalId'
              element={<BrokerOTPValidation />}
            />
            <Route
              path='/manageridvalidation'
              element={<CompanyManagerIDValidation />}
            />
            <Route
              path='/manageridvalidation-version2'
              element={<CompanyManagerIDValidation />}
            />
            <Route
              path='/managerotp/:managerId'
              element={<CompanyManagerOTPValidation />}
            />
            <Route
              path='/companylocation/:lat/:lng/:address'
              element={<CompanyLocationVerfication />}
            />

            <Route path='/otp/:countryCode/:phone' element={<OTP />} />
            <Route
              path='/otp-agent/:countryCode/:phone'
              element={<OTPAgent />}
            />

            <Route path='/error' element={<Error />} />
            {/* <Route path='/news' element={<News />} /> */}
            <Route path='/project/:id' element={<ProjectDetails />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/search-map' element={<SearchMap />} />
            <Route
              path='/search-map/:listedFor/:propertyType/:lat/:long/:sort/:zoom/:page'
              element={<SearchMap />}
            />
            {/* <Route
              path='/add-property/:listingType/:propertyType'
              element={<AddNew />}
            />
            <Route path='/add-property' element={<AddNew />} />
            <Route path='/add-property-step3/:id' element={<AddNewStep3 />} />
            <Route
              path='/add-property-step3/:id/:userId'
              element={<AddNewStep3 />}
            /> */}
            <Route
              path='/add-property/:listingType/:propertyType'
              element={
                !window.location.href.includes(".com") ? (
                  <REGAAddNew2 />
                ) : (
                  <REGAAddNew />
                )
              }
            />
            <Route
              path='/add-property'
              element={
                !window.location.href.includes(".com") ? (
                  <REGAAddNew2 />
                ) : (
                  <REGAAddNew />
                )
              }
            />
            <Route
              path='/add-property/:userId'
              element={
                !window.location.href.includes(".com") ? (
                  <REGAAddNew2 />
                ) : (
                  <REGAAddNew />
                )
              }
            />
            <Route
              path='/add-property-step3/:id'
              element={
                !window.location.href.includes(".com") ? (
                  <REGAAddNew2Step3 />
                ) : (
                  <REGAAddNewStep3 />
                )
              }
            />
            <Route
              path='/add-property-step3/:id/:userId'
              element={
                !window.location.href.includes(".com") ? (
                  <REGAAddNew2Step3 />
                ) : (
                  <REGAAddNewStep3 />
                )
              }
            />
            <Route
              path='/edit-property/:id'
              element={
                !window.location.href.includes(".com") ? (
                  <REGAEditProperty2 />
                ) : (
                  <REGAEditProperty />
                )
              }
            />

            <Route path='/my-listing' element={<MyListing />} />
            <Route path='/comparison' element={<Comparison />} />
            <Route path='/comparison-list' element={<ComparisonList />} />
            <Route path='/my-favorite' element={<MyFavorite />} />
            <Route path='Chats' element={<Chat />}>
              <Route path=':id' element={<Chat />} />
            </Route>
            <Route path='/CSChats' element={<CsChat />}>
              <Route path=':id' element={<CsChat />} />
            </Route>
            <Route path='/Tickets' element={<Ticket />}>
              <Route path=':id' element={<Ticket />} />
            </Route>
            <Route path='/user-profile/:id' element={<UserProfile />} />
            <Route path='/user-profile' element={<UserProfile />} />
            <Route path='/company-profile/:id' element={<CompanyProfile />} />
            <Route path='/company-profile' element={<CompanyProfile />} />
            <Route path='/my-profile' element={<MyProfile />} />
            <Route path='/change-phone' element={<ChangePhone />} />
            <Route path='/iamlink/:userId' element={<IAMLink />} />
            {/* <Route path='/checkadnumber' element={<CheckAdvertiserNumber />} /> */}
            <Route
              path='/change-agent-phone/:agentId'
              element={<ChangeAgentPhone />}
            />
            <Route path='/forgot-password' element={<ForgotPassword />} />

            <Route
              path='/otp-change-password/:countryCode/:phone'
              element={<OTPPassword />}
            />

            <Route path='/change-password' element={<ChangePassword />} />
            <Route
              path='/otp-change-phone/:countryCode/:newPhone'
              element={<OtpChangePhone />}
            />

            <Route path='/Ticket/:propertyId' element={<Ticket />} />
            <Route path='/agents' element={<Agents />} />
            {/* <Route
              path='/verified-companies'
              element={<VerifiedCompanies />}
              exact
            /> */}

            <Route path='/verify' element={<Verify2 />} />
            {/* <Route path='/verify2' element={<Verify2 />} /> */}
            <Route path='/minasati' element={<WhiteLabel />}>
              <Route path=':id' element={<WhiteLabel />} />
              {/* <Route path=':planId/:planPrice/:planName' element={<WhiteLabel />} /> */}
            </Route>
            <Route path='/pro' element={<WhiteLabel />}>
              <Route path=':id' element={<WhiteLabel />} />
            </Route>
            <Route path='/amakkn-pro' element={<WhiteLabel />} />
            <Route path='/minasati-step2' element={<WhiteLabel2 />} />
            <Route path='/minasati-step2/:id' element={<WhiteLabel2 />} exact />
            {vendor !== "amakkn" && (
              <Route path='/admin/*' element={<Admin />} />
            )}
            <Route path='/projects' element={<Projects />} />
            <Route path='/news/:id' element={<News />} />
            <Route path='/blog' element={<Blog />} />
            <Route
              path='/properties/:listedFor/:propertyType/:lat/:long/:sort/:zoom/:page'
              element={<Properties />}
            />
            <Route path='/properties' element={<Properties />} />
            <Route path='/add-agent' element={<AddAgent />} />
            <Route path='/edit-agent/:agentId' element={<EditAgent />} />
            <Route
              path='/otp-add-agent/:countryCode/:phone'
              element={<OTPAddAgent />}
              exact
            />
            <Route path='/401' element={<UnAuthorized />} />
            <Route path='/ejar' element={<Ejar />} />
            <Route path='/wlVerify/:code' element={<EmailVerification />} />
            <Route path='pro-users' element={<WLUsers />} />
            <Route path='pro-users/:domain' element={<WLUserDetails />} />

            <Route path='/delete-my-account' element={<DeleteMyAccount />}>
              <Route path=':userId' element={<DeleteMyAccount />} />
            </Route>

            <Route
              path='/delete-usage-information'
              element={<DeleteUsageInfo />}>
              <Route path=':userId' element={<DeleteUsageInfo />} />
            </Route>
            <Route path='/promo-codes' element={<PromoCodes />}>
              <Route path=':userId' element={<PromoCodes />} />
            </Route>
            <Route path='/get-property-image/:id' element={<PropertyPapper />}>
              <Route path=':type' element={<PropertyPapper />} />
            </Route>

            {vendor !== "amakkn" && (
              <Route
                path='/intellectual-property-rights'
                element={<WLIntellectualPropertyRights />}
              />
            )}
            <Route path='/*' element={<Error />} />
          </Routes>
        </Router>
        <ToastContainer
          position={i18n.language === "ar" ? 'top-right' : 'top-left'}
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={i18n.language === "ar"}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {vendor === "amakkn" &&
          !window.location.href.includes("plan-registration") &&
          !window.location.href.includes("/pro") &&
          !window.location.href.includes("my-subscription") && (
            <CookieConsentBar />
          )}
        <OnlineDetection />
        {vendor !== "amakkn" && !window.location.href.includes("/admin") && (
          <NewVisitingDetection />
        )}
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default Root;

root.render(<Root />);
