import React, { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Data from "./data";
import Dashboard from "../../scenes/admin/dashboard";
import StylesPage from "../../scenes/admin/styles";
// import Services from "./components/Services";
import ContactInfo from "../../scenes/admin/contact";
import SectionsPage from "../../scenes/admin/sections";

import REGAAddNew from "../../scenes/shared/rega-properties/add-property";
import REGAEditProperty from "../../scenes/shared/rega-properties/edit-property";
import REGAAddNewStep3 from "../../scenes/shared/rega-properties/add-property-step3";
// import REGAAddNew2 from "../../scenes/shared/rega-properties2/add-property";
// import REGAEditProperty2 from "../../scenes/shared/rega-properties2/edit-property";
// import REGAAddNew2Step3 from "../../scenes/shared/rega-properties2/add-property-step3";

import MyListing from "../../scenes/shared/properties";
import TemplateSelector from "../../scenes/admin/templates";
import AboutUs from "../../scenes/admin/about-us";
import EditProject from "../../scenes/admin/projects/edit-project";
import AdminLayout from "../layouts/admin-layout";
import Projects from "../../scenes/admin/projects/index";
import AddProject from "../../scenes/admin/projects/add-project";
import defaultTheme, { useAuth } from "../../helpers/context";
import Domain from "../../scenes/admin/domain";
import ClientsMessagesPage from "../../scenes/admin/clients-messages";
import Subscriptions from "../../scenes/admin/subscriptions";
import PropertyDetails from "../../scenes/shared/properties/property-details";
import ProjectDetails from "../projectDetails";
// import EjarRequestsPage from "../../scenes/admin/ejar-requests";
import { useEffect } from "react";
import WLLogin from "../../scenes/white-label/login";
// import CommingSoon from "../global-components/comming-soon";
import WLAgents from "../../scenes/admin/agents";
import WLAddAgent from "../../scenes/admin/agents/add-agent";
import WLOTP from "../../scenes/admin/agents/otp-page";
import WLSetPassword from "../../scenes/admin/agents/agent-set-password";
import WLEditAgent from "../../scenes/admin/agents/edit-agent";
import WLChangeAgentPhone from "../../scenes/admin/agents/change-agent-phone";
import WLOTPChangePhone from "../../scenes/admin/agents/otp-change-phone";
import UserProfile from "../user-profile";
import MyProfile from "../../scenes/amakkn/profile";
import Chat from "../Chats/Chats";
import Ticket from "../Tickets/CSChats";
import ChangePhone from "../../scenes/amakkn/profile/components/change-phone";
import OTPChangePhone from "../section-components/registration/otpChangePhone";
import OTPAgent from "../section-components/registration/OTPAgent";
import AgentSetPassword from "../section-components/registration/AgentSetPasswordPage";
import WLForgotPassword from "../../scenes/white-label/forgot-password";
import WLOTPPassword from "../../scenes/white-label/otp-password";
import WLSetNewPassword from "../../scenes/white-label/set-new-password";
import SuccessResetPassword from "../section-components/registration/SuccessResetPasswordPage";
// import FAL from "../../scenes/admin/fal";
import Blog from "../../scenes/admin/blog";
import NewsDetails from "../../scenes/white-label/news";
import AddOrUpdateNews from "../../scenes/admin/blog/add-or-update-news";
import ReportsPage from "../../scenes/admin/reports";
import AddOrEditCustomer from "../../scenes/admin/customers/add-edit-customer";
import CustomersPage from "../../scenes/admin/customers";
import MatchedCustomersPage from "../../scenes/admin/customers/matched-customers";
import { DARK_TEMPLATES } from "../../constants";
import CompanyProfile from "../../scenes/amakkn/company-profile";
import DealsPage from "../../scenes/admin/deals";
import AddOrEditDeal from "../../scenes/admin/deals/add-edit-deal";
import TasksPage from "../../scenes/admin/tasks";
import AddTask from "../../scenes/admin/tasks/add-task";
import EditTask from "../../scenes/admin/tasks/edit-task";
import InterestedCustomersPage from "../../scenes/admin/customers/interested-customers";
import { useTranslation } from "react-i18next";
import Addons from "../../scenes/admin/addons";
import AdsComplaintsPage from "../../scenes/admin/ads-complaints";

const Admin = () => {
  const { templateId } = useAuth();
  const { t, i18n } = useTranslation();
  const [navDrawerOpen, setNavDrawerOpen] = useState(
    window &&
      window.innerWidth &&
      window.innerWidth >= defaultTheme.breakpoints.values.md
      ? true
      : false
  );
  const isLoginPage = window.location.href.includes('/login') || window.location.href.includes('/forgot-password') || window.location.href.includes('otp') || window.location.href.includes('set-new-password') || window.location.href.includes('success-reset-password');
  const handleChangeNavDrawer = () => {
    setNavDrawerOpen(!navDrawerOpen);
  };
  
  const location = useLocation();
  useEffect(() => {
    document.querySelector('.admin-container')?.scrollIntoView({ behavior: 'smooth' });
  }, [location]);

  return (
    <Box sx={{ display: "flex", background: DARK_TEMPLATES.includes(+templateId) ? 'var(--main-color-two)' : 'inherit' }}>
      <AdminLayout
        handleChangeNavDrawer={handleChangeNavDrawer}
        navDrawerOpen={navDrawerOpen}
        setNavDrawerOpen={setNavDrawerOpen}
        menus={Data.menus}>
        <Routes>
          <Route path='/' element={<Dashboard />} />
          <Route path='login' element={<WLLogin />} />
          <Route path='/login'>
            <Route index element={<WLLogin />} />
            <Route path=':token2/:userId2/:name2' element={<WLLogin />} />
          </Route>
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='style' element={<StylesPage />} />
          <Route path='clients-messages' element={<ClientsMessagesPage />} />
          <Route path='contact-info' element={<ContactInfo />} />
          <Route path='sections' element={<SectionsPage />} />
          <Route path='property/add/:listingType/:propertyType' element={<REGAAddNew />} />
          <Route path='property/add' element={<REGAAddNew />} />
          <Route path='property/add-step3/:id' element={<REGAAddNewStep3 />} />
          <Route path='property/edit-property/:id' element={<REGAEditProperty />} />
          {/* <Route path='crm' element={<CommingSoon />} /> */}
          <Route path='domain' element={<Domain />} />
          <Route path='plans' element={<Subscriptions />} />
          <Route path='plans/:planId/:planName/:planPrice/:planDuration' element={<Subscriptions />} />
          <Route
            path='otp-change-password/:countryCode/:phone'
            element={<WLOTPPassword />}
          />
          <Route
            path='set-new-password/:countryCode/:phone'
            element={<WLSetNewPassword />}
          />
          <Route path='profile' element={<MyProfile />} />
          <Route path='templates' element={<TemplateSelector />} />
          <Route path='about' element={<AboutUs />} />
          <Route path='property/my-listings' element={<MyListing />} />
          <Route path='projects' element={<Projects />} />
          <Route path='/project'>
            <Route index element={<AddProject />} />
            <Route path=':id' element={<EditProject />} />
          </Route>
          <Route path='/edit-project/:id' element={<EditProject />} />
          <Route path='/add-project/:id' element={<EditProject />} />
          <Route path='/property-details/:id' element={<PropertyDetails />} />
          <Route path='/project-details/:id' element={<ProjectDetails />} />
          {/* <Route path="/ejar-requests" element={<CommingSoon />} /> <EjarRequestsPage /> */}
          <Route path='agents' element={<WLAgents />} />
          <Route path='add-agent' element={<WLAddAgent />} />
          <Route path='otp/:countryCode/:phone' element={<WLOTP />} />
          <Route path='otp-agent/:countryCode/:phone' element={<OTPAgent />} />
          <Route path='/setpassword/:countryCode/:phone' element={<WLSetPassword />} />
          <Route path='/agent-setpassword/:countryCode/:phone' element={<AgentSetPassword />} />
          <Route path='/edit-agent/:agentId' element={<WLEditAgent />} />
          <Route path='/change-agent-phone/:agentId' element={<WLChangeAgentPhone />} />
          <Route
            path='otp-phone/:countryCode/:newPhone'
            element={<OTPChangePhone />}
          />
          <Route path='/otp-change-phone/:agentId/:countryCode/:newPhone' element={<WLOTPChangePhone />} />
         
          <Route path='/user-profile/:id' element={<UserProfile />} />
          <Route path='/company-profile/:id' element={<CompanyProfile />} />
          <Route path='chat' element={<Chat />}>
            <Route path=':id' element={<Chat />} />
          </Route>
          <Route path='ticket' element={<Ticket />}>
            <Route path=':id' element={<Ticket />} />
          </Route>
          <Route path='change-phone' element={<ChangePhone />} />
          <Route path='forgot-password' element={<WLForgotPassword />} />
          <Route path='success-reset-password' element={<SuccessResetPassword />} />
          {/* <Route path='request-fal' element={<FAL />} /> */}
          <Route path='blog' element={<Blog />} />
          <Route path='news'>
            <Route index element={<AddOrUpdateNews />} />
            <Route path=':id' element={<AddOrUpdateNews />} />
          </Route>
          <Route path='/edit-news/:id' element={<AddOrUpdateNews />} />
          <Route path='/news-details/:id' element={<NewsDetails />} />
          <Route path="/reports" element={<ReportsPage />} />
          <Route path="/ads-complaints" element={<AdsComplaintsPage />} />
          
          <Route path="/customers" element={<CustomersPage />} />
          <Route path="/add-customer" element={<AddOrEditCustomer />} />
          <Route path="/edit-customer/:id" element={<AddOrEditCustomer />} />
          <Route path="/matched-customers" element={<MatchedCustomersPage />} />
          <Route path="/interested-customers" element={<InterestedCustomersPage />} />
          <Route path="/deals" element={<DealsPage />} />
          <Route path='add-deal' element={<AddOrEditDeal />}>
            <Route path=':customerId/:interestId' element={<AddOrEditDeal />} />
            <Route path=':customerId/:interestId/:propertyId' element={<AddOrEditDeal />} />
          </Route>
          <Route path="/edit-deal/:id" element={<AddOrEditDeal />} />
          <Route path="/tasks" element={<TasksPage />} />
          <Route path="/add-task" element={<AddTask />} />
          <Route path="/add-task-step2/:id" element={<EditTask />} />
          <Route path="/edit-task/:id" element={<EditTask />} />
          <Route path="/addons" element={<Addons />} />
          
        </Routes>
        {isLoginPage && <div
          className='w-100 d-flex align-items-center justify-content-center'
          style={{ bottom: isLoginPage ? "10px" : "-40px", position: isLoginPage ? 'fixed' : 'absolute',color:DARK_TEMPLATES.includes(+templateId)?'#fff':'inherit' }}>
          <span>{t("ServiceProvidedBy")}</span>
          <a href='https://www.amakkn.com/'>
            <img
              src={i18n.language === "ar" ? `/assets/img/logo/amakkn-logo${DARK_TEMPLATES.includes(+templateId) ? '-dark' : ''}.svg` : `/assets/img/amakknLogo.png`}
              alt='amakkn-logo'
              style={{
                position: "relative",
                top: "-2px",
                right: i18n.language === "ar" ? '6px' : "unset",
                marginInline: i18n.language !== "ar" ? '6px' : "0",
                cursor: "pointer",
                width: i18n.language === "ar" ? "60px" : '30px',
              }}
            />
            {i18n.language !== "ar" && <span>Amakkn</span>}

          </a>
        </div>}
      </AdminLayout>
    </Box>
  );
}

Admin.propTypes = {
  children: PropTypes.element,
};

export default Admin;
