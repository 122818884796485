import React, {  useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../helpers/context";
import AppLayout from "../../components/layouts/app-layout";
import { toast } from "react-toastify";
import { Twitter } from "../../constants/icons";
import { Box, CircularProgress, ClickAwayListener, Dialog, DialogContent, TextField, Tooltip } from "@mui/material";
import { getMyPromo } from "../../api/propertyApi";
import ShareIcon from "@mui/icons-material/Share";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from '@mui/icons-material/Facebook';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import NoData from "../../components/global-components/no-data";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

const PromoCodes = () => {
  const { token } = useAuth();
  let { userId } = useParams();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [openShareModal, setOpenShareModal] = useState({ selectedPromo: undefined, visible: false });
  const [openTooltip, setOpenTooltip] = useState(false);
  
  const decoration = (
    <svg xmlns="http://www.w3.org/2000/svg" width="151" height="131" viewBox="0 0 151 131">
      <path fillRule="evenodd" fill="var(--main-color-one)" d="M5.5,2A3.5,3.5,0,1,1,2,5.5,3.5,3.5,0,0,1,5.5,2Zm0,20A3.5,3.5,0,1,1,2,25.5,3.5,3.5,0,0,1,5.5,22Zm0,20A3.5,3.5,0,1,1,2,45.5,3.5,3.5,0,0,1,5.5,42Zm0,20A3.5,3.5,0,1,1,2,65.5,3.5,3.5,0,0,1,5.5,62Zm0,20A3.5,3.5,0,1,1,2,85.5,3.5,3.5,0,0,1,5.5,82Zm0,20A3.5,3.5,0,1,1,2,105.5,3.5,3.5,0,0,1,5.5,102Zm0,20A3.5,3.5,0,1,1,2,125.5,3.5,3.5,0,0,1,5.5,122Z" />
      <path fillRule="evenodd" fill="var(--main-color-one)" d="M25.5,2A3.5,3.5,0,1,1,22,5.5,3.5,3.5,0,0,1,25.5,2Zm0,20A3.5,3.5,0,1,1,22,25.5,3.5,3.5,0,0,1,25.5,22Zm0,20A3.5,3.5,0,1,1,22,45.5,3.5,3.5,0,0,1,25.5,42Zm0,20A3.5,3.5,0,1,1,22,65.5,3.5,3.5,0,0,1,25.5,62Zm0,20A3.5,3.5,0,1,1,22,85.5,3.5,3.5,0,0,1,25.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,25.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,25.5,122Z" />
      <path fillRule="evenodd" fill="var(--main-color-one)" d="M45.5,2A3.5,3.5,0,1,1,42,5.5,3.5,3.5,0,0,1,45.5,2Zm0,20A3.5,3.5,0,1,1,42,25.5,3.5,3.5,0,0,1,45.5,22Zm0,20A3.5,3.5,0,1,1,42,45.5,3.5,3.5,0,0,1,45.5,42Zm0,20A3.5,3.5,0,1,1,42,65.5,3.5,3.5,0,0,1,45.5,62Zm0,20A3.5,3.5,0,1,1,42,85.5,3.5,3.5,0,0,1,45.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,45.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,45.5,122Z" />
      <path fillRule="evenodd" fill="var(--main-color-one)" d="M65.5,2A3.5,3.5,0,1,1,62,5.5,3.5,3.5,0,0,1,65.5,2Zm0,20A3.5,3.5,0,1,1,62,25.5,3.5,3.5,0,0,1,65.5,22Zm0,20A3.5,3.5,0,1,1,62,45.5,3.5,3.5,0,0,1,65.5,42Zm0,20A3.5,3.5,0,1,1,62,65.5,3.5,3.5,0,0,1,65.5,62Zm0,20A3.5,3.5,0,1,1,62,85.5,3.5,3.5,0,0,1,65.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,65.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,65.5,122Z" />
      <path fillRule="evenodd" fill="var(--main-color-one)" d="M85.5,2A3.5,3.5,0,1,1,82,5.5,3.5,3.5,0,0,1,85.5,2Zm0,20A3.5,3.5,0,1,1,82,25.5,3.5,3.5,0,0,1,85.5,22Zm0,20A3.5,3.5,0,1,1,82,45.5,3.5,3.5,0,0,1,85.5,42Zm0,20A3.5,3.5,0,1,1,82,65.5,3.5,3.5,0,0,1,85.5,62Zm0,20A3.5,3.5,0,1,1,82,85.5,3.5,3.5,0,0,1,85.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,85.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,85.5,122Z" />
      <path fillRule="evenodd" fill="var(--main-color-one)" d="M105.5,2A3.5,3.5,0,1,1,102,5.5,3.5,3.5,0,0,1,105.5,2Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,105.5,22Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,105.5,42Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,105.5,62Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,105.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,105.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,105.5,122Z" />
      <path fillRule="evenodd" fill="var(--main-color-one)" d="M125.5,2A3.5,3.5,0,1,1,122,5.5,3.5,3.5,0,0,1,125.5,2Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,125.5,22Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,125.5,42Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,125.5,62Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,125.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,125.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,125.5,122Z" />
      <path fillRule="evenodd" fill="var(--main-color-one)" d="M145.5,2A3.5,3.5,0,1,1,142,5.5,3.5,3.5,0,0,1,145.5,2Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,145.5,22Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,145.5,42Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,145.5,62Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,145.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,145.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,145.5,122Z" />
    </svg>
  );
  
  const getUserData = (id) => {
    setLoadingData(true);
    getMyPromo({ userId: id ? id : token }).then((res) => {
      if (res.resCode === 0) {
        setData(res.response);
      } else {
        toast.error(res.resStr);
      }
    }).finally(() => setLoadingData(false));
  };

  useEffect(
    () => {
      if (userId) {
        getUserData(userId);
      } else {
        getUserData();
      }
    }, // eslint-disable-next-line
    [userId]
  );

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <AppLayout needAuth={!userId} pageTitle={t("PromoCodes")} withoutNav={userId} withFooter={(!userId || (data && data?.filter(i => i.hasExpired !== '1')?.length !== 0))}>
      <div className={`page-width ${userId ? 'full-height' : ''} ${data && data?.filter(i => i.hasExpired !== '1')?.length > 0 ? '' : 'my-5 py-5'}`} style={{ top: userId ? 0 : '' }}>
        {loadingData ? <CircularProgress />
          : (data && data?.filter(i => i.hasExpired !== '1')?.length > 0 ? (
            <>
              <div>
                <section className="home-section">
                  <div className="bg-shape-5 d-none d-lg-block"></div>
                  <div className="bg-shape-6"></div>
                  <div className="container position-relative min-height-100vh d-flex align-items-center pb-100 pt-100 mx-auto">
                    <div className="home-content text-start">
                      <div className="row mx-0">
                        <div className="col-lg-6 d-flex align-items-center mb-md-60 mb-sm-40">
                          <div className="w-100 text-center text-lg-start">
                            <h1 className="section-title mb-3" style={{ lineHeight: 1.5 }}>{t("AmakknDiscountProgram")}</h1>
                            <p className="section-descr mb-5">{t("SharePromo")}</p>
                            {data && data?.filter(i => i.hasExpired !== '1')?.length > 0 && data?.filter(i => i.hasExpired !== '1')?.map((promoCode, i) => (
                              <div className="d-flex mb-3" style={{ gap: '15px' }} key={i}>
                                <div className="d-flex flex-column" style={{ gap: '10px' }}>
                                  <TextField
                                    label={i === 0 ? t("YourDiscountCode") : t("AnotherDiscountCode")}
                                    value={promoCode.code}
                                    disabled
                                    variant="outlined"
                                  />
                                  <span style={{ fontSize: '12px' }}>{t("discountOf", { num: promoCode.discountPercentage })}</span>
                                </div>
                                <button className='btn fav-btn btn-white-bg btn-info-bg text-info info-border mt-1'
                                  onClick={() => setOpenShareModal({ visible: true, selectedPromo: promoCode })}
                                >
                                  <span>{t("Share")}</span>
                                  <ShareIcon />
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                          <div className="w-100 ps-xl-3">
                            <div className="composition">
                              <div className="composition-decoration-1">
                                {decoration}
                              </div>
                              <div className="composition-decoration-2">
                                {decoration}
                              </div>
                              <div className="composition-image">
                                <img src={publicUrl + `assets/img/referral.svg`} alt={'Referral program'} />
                              </div>
                            </div>
                          </div>
                        </div>
            
                      </div>
                    </div>
                  </div>
                </section>
          
                <div className="container mx-auto">
                  <Box
                    sx={{
                      marginBottom: "40px",
                      textAlign: "center !important",
                      fontSize: "28px",
                      fontWeight: 600,
                      color: "#000",
                      mt: '50px'
                    }}>
                    {t("HowToBenefitFromTheAmakknDiscountProgram")}
                  </Box>
                  <div className="row mx-0">
                    <div className="col-12">
                      <div className="road-map-main">
                        <div className="road-map-wrapper">
                          <div className="road-map-circle">
                            <span className="road-map-circle-text d-flex align-items-center justify-content-center">{t("Step") + " 1"}</span>
                          </div>
                          <div className="road-map-card">
                            <h4 className="card-head">{t("ShareDiscountCode")}</h4>
                            <p className="card-text">{t("ShareDiscountCodeDesc")}</p>
                          </div>
                        </div>
                        <div className="road-map-wrapper">
                          <div className="road-map-circle">
                            <span className="road-map-circle-text d-flex align-items-center justify-content-center">{t("Step") + " 2"}</span>
                          </div>
                          <div className="road-map-card">
                            <h4 className="card-head">{t("YourFriendJoinsAmakkn")}</h4>
                            <p className="card-text">{t("YourFriendJoinsAmakknDesc")}</p>
                          </div>
                        </div>
                        <div className="road-map-wrapper">
                          <div className="road-map-circle">
                            <span className="road-map-circle-text d-flex align-items-center justify-content-center">{t("Step") + " 3"}</span>
                          </div>
                          <div className="road-map-card">
                            <h4 className="card-head">{t("GetTheReward")}</h4>
                            <p className="card-text">{t("GetTheRewardDesc")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          
              </div>
              <Dialog
                onClose={() => setOpenShareModal({ visible: false, selectedPromo: undefined })}
                className="custom-dialog no-padding full-screen2"
                open={openShareModal.visible}
              >
                <DialogContent sx={{ padding: 0, overflow: 'hidden' }}>
                  <div className='modal2 '>
                    <button className='close' onClick={() => setOpenShareModal({ visible: false, selectedPromo: undefined })}>
                      &times;
                    </button>
                    <div className='social-modal'>
                      <div className='social-items-wrapper'>
                        <ClickAwayListener
                          onClickAway={() => setOpenTooltip(false)}
                        >
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            sx={{ zIndex: '99999999999999999' }}
                            onClose={() => setOpenTooltip(false)}
                            open={openTooltip}
                            // disableFocusListener
                            disableHoverListener
                            placement="left"
                            // disableTouchListener
                            title={t("CopiedSuccessfully")}>
                            <div
                              className='social-item'
                              onMouseLeave={() =>
                                setOpenTooltip(false)
                              }
                              onClick={() => {
                                navigator?.clipboard?.writeText(`${openShareModal.selectedPromo.code}`);
                                setOpenTooltip(true);
                              }}>
                              <ContentCopyIcon />
                              <span>{t("CopyCode")}</span>
                            </div>
                          </Tooltip>
                        </ClickAwayListener>

                                  
                        <div className='social-item'
                          onClick={() => {
                            window.open(encodeURI(`https://api.whatsapp.com/send?text=${t("PromoCodeMsg", { code: openShareModal.selectedPromo.code, discount: openShareModal.selectedPromo.discountPercentage })}\n https://amakkn${window.location.origin.includes('amakkncompany.com') || process.env.REACT_APP_API_URL?.includes('devsa2') ? '.org' : '.com'}`));
                          }}>
                          
                          <WhatsAppIcon />
                          <span>{t("WhatsApp")}</span>
                        </div>
                                  
                        <div className='social-item'
                          onClick={() => {
                            window.open('http://facebook.com/sharer/sharer.php?u=' + encodeURIComponent(`${t("PromoCodeMsg", { code: openShareModal.selectedPromo.code, discount: openShareModal.selectedPromo.discountPercentage })} https://amakkn${window.location.origin.includes('amakkncompany.com') || process.env.REACT_APP_API_URL?.includes('devsa2') ? '.org' : '.com'}`), '', 'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0')
                          }}>
                          <FacebookIcon />
                          <span>{t("Facebook")}</span>
                        </div>
                                    
                        <div className='social-item'
                          onClick={() => {
                            window.open('https://twitter.com/intent/tweet?text=' + encodeURIComponent(`${t("PromoCodeMsg", { code: openShareModal.selectedPromo.code, discount: openShareModal.selectedPromo.discountPercentage })} https://amakkn${window.location.origin.includes('amakkncompany.com') || process.env.REACT_APP_API_URL?.includes('devsa2') ? '.org' : '.com'}`))
                          }}>
                          <Twitter medium />
                          <span>X</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            </>
          ) :
            <NoData msg={t("ThereAreNoValidDiscountCodesToUse")} />)
        }
      </div>
    </AppLayout>
  );
}

export default PromoCodes;
