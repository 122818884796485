import React, { useState } from 'react'
import CollectionsIcon from '@mui/icons-material/Collections';
import { Dialog, DialogContent, useMediaQuery } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination,EffectFade, Keyboard  } from 'swiper';
import Close from '@mui/icons-material/Close';

export default function GalleryV1({ images }) {
    const [hoveredImage, setHoveredImage] = useState(0);
    const [showGalleryModal, setShowGalleryModal] = useState(false);
    const { i18n } = useTranslation();
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

    return (
        <>
            <div className="gallery-wrap">
                {images.map((item, i) => i <= 6 &&
                    <div
                        key={i}
                        onMouseEnter={() => isLargeScreen ? setHoveredImage((i !== images.length - 1 && i !== 6) ? i : 0) : {}}
                        onMouseLeave={() => isLargeScreen ? setHoveredImage(0) : {}}
                        onClick={() => isLargeScreen ? {} : setHoveredImage((i !== images.length - 1 && i !== 6) ? i : 0)}
                        className={`item ${i === hoveredImage ? 'hovered' : ''} ${(i === images.length - 1 && i === 6) ? 'last' : ''}`}
                        style={{ background: `url(${item.original}) center no-repeat ${item.original.endsWith('svg') ? '#D8D8D8' : ''}`, backgroundSize: !item.original.endsWith('svg') ? 'cover' : 'contain' }}>
                        {item.description && i === hoveredImage && <span>{item.description}</span>}
                        {(i === images.length - 1 || i === 6) && <CollectionsIcon onClick={() => setShowGalleryModal(true)} sx={{ color: '#fff', fontSize: '35px', cursor: 'pointer' }} />}
                    </div>)}
            </div>
            <Dialog
                onClose={() => setShowGalleryModal(false)}
                className='custom-dialog no-padding gallery full-screen2'
                open={showGalleryModal}>
                <DialogContent sx={{ padding: 0, overflow: "hidden" }}>
                    <span className='close-btn' onClick={() => setShowGalleryModal(false)}>
                        <Close sx={{ color: '#d50000', fontSize: '30px', cursor: 'pointer' }} />
                    </span>
                    <div className='modal2 w-100 h-100'>
                        <Swiper
                            key={i18n.language + 'ii'}
                            modules={[Navigation, Pagination, EffectFade, Keyboard]}
                            keyboard={{ enabled: true }}
                            spaceBetween={0}
                            style={{ width: '100%', height: '100%' }}
                            slidesPerView={1}
                        
                            navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                            pagination={{ clickable: true, el: '.swiper-pagination' }}
                            effect="fade"
                            speed={2600}
                        >
                            {images.map((item, i) => (
                                <SwiperSlide key={i}> <img src={item.original} alt='img' />{item.description && <span> {item.description}</span>}</SwiperSlide>
                            ))}
                        </Swiper>
                        {images.length > 1 &&
                            <>
                                <div className="swiper-pagination"></div>
                                <div className="swiper-button-next"><ChevronLeft sx={{ fontSize: '30px' }} /></div>
                                <div className="swiper-button-prev"><ChevronRight sx={{ fontSize: '30px' }} /></div>
                            </>
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}
