import i18next from "i18next";
import { handleResponse, handleError } from "./apiUtils";
const baseUrl = window.location.origin.includes('amakkncompany.com') ? 'https://devsa2.amakkn.com' : process.env.REACT_APP_API_URL;
const baseUrlOfChat = process.env.REACT_APP_CHAT_URL;

function POST(data) {
  return {
    method: "POST", // POST for create, PUT to update when id already exists.
    headers: { Accept: "application/json", "content-type": "application/json" },
    body: JSON.stringify({
      ...data,
    }),
  };
}
export function getWLPlans(data) {
  return fetch(baseUrl + "/Login/getWLPlans/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function saveUser(data) {
  return fetch(baseUrl + "/Login/signupIndividualUser/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getUserProile(data) {
  return fetch(baseUrl + "/Login/getProfileNew/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function saveLicenseNumber(data) {
  return fetch(baseUrl + "/Login/saveLicenseNumber/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function checkIfAdvertiserNumberIsValid(data) {
  return fetch(baseUrl + "/Login/checkIfAdvertiserNumberIsValid/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function saveProfile(data) {
  return fetch(baseUrl + "/Login/saveProfile/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function showOrHideFamilyName(data) {
  return fetch(baseUrl + "/Login/showOrHideFamilyName/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function verifyUser(data) {
  return fetch(baseUrl + "/Login/verifyUser/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function setPasswordForUser(data) {
  return fetch(baseUrl + "/Login/setPasswordForUser/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function VerifyBrokerID(data) {
  return fetch(baseUrl + "/Login/requestYaqeenOTPForUser/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function verifyBrokerOTP(data) {
  return fetch(baseUrl + "/Login/verifyYaqeenOTPAndUserDetails/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function VerifyCompanyCR(data) {
  return fetch(baseUrl + "/Login/checkCR/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function VerifyCompanyManagerID(data) {
  return fetch(
    baseUrl + "/Login/checkManagerInfoAndRequestYaqeenOTP/",
    POST(data)
  )
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function verifyManagerOTP(data) {
  return fetch(baseUrl + "/Login/verifyYaqeenOTPAndGetListOfCRs/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function verifyCompanyLocation(data) {
  return fetch(baseUrl + "/Login/saveLocationsOfCompanies/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function loginUser(data) {
  return fetch(baseUrl + "/Login/loginUser/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getListOfChannelsForUser(data) {
  return fetch(baseUrl + "/Login/getListOfChannelsForUser/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getListOfChannelsForCustomerSupport(data) {
  return fetch(
    baseUrl + "/Login/getListOfChannelsForCustomerSupport/",
    POST(data)
  )
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function initiateSupportChatChannel(data) {
  return fetch(baseUrl + "/Login/initiateSupportChatChannel/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getListOfSubjects(data) {
  return fetch(baseUrl + "/Login/getListOfSubjects/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getNewMessages(data) {
  return fetch(baseUrlOfChat + "/getNewMessages/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getState(data) {
  return fetch(baseUrlOfChat + "/getState/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function saveState(data) {
  return fetch(baseUrlOfChat + "/saveState/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function saveLastMessage(data) {
  return fetch(baseUrl + "/Login/saveLastMessage/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function saveSupportLastMessage(data) {
  return fetch(baseUrl + "/Login/saveSupportLastMessage/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function uploadProfileImage(data) {
  return fetch(baseUrl + "/Property/uploadProfileImage/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function uploadProfileImageForAgent(data) {
  return fetch(baseUrl + "/Property/uploadProfileImageForAgent/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function initiateChatChannel(data) {
  return fetch(baseUrl + "/Login/initiateChatChannel/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function setPushTokenForUserNew(data) {
  return fetch(baseUrl + "/Login/setPushTokenForUserNew/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function saveProfilePictureNew(data) {
  return fetch(baseUrl + "/Login/saveProfilePictureNew/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function changeMobileNumberGenerateCode(data) {
  return fetch(baseUrl + "/Login/changeMobileNumberGenerateCode/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function changeMobileNumberConfirmCode(data) {
  return fetch(baseUrl + "/Login/changeMobileNumberConfirmCode/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function changePassword(data) {
  return fetch(baseUrl + "/Login/changePassword/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

//--------Agents--------
export function getAgentsForCompany(data) {
  return fetch(baseUrl + "/Login/getAgentsForCompany/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function addAgent(data) {
  return fetch(baseUrl + "/Login/addAgent/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function deleteAgent(data) {
  return fetch(baseUrl + "/Login/deleteAgent/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function editAgent(data) {
  return fetch(baseUrl + "/Login/editAgent/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function changePasswordForAgent(data) {
  return fetch(baseUrl + "/Login/changePasswordForAgent/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function changeMobileNumberConfirmCodeForAgent(data) {
  return fetch(
    baseUrl + "/Login/changeMobileNumberConfirmCodeForAgent/",
    POST(data)
  )
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function saveProfilePictureForAgent(data) {
  return fetch(baseUrl + "/Login/saveProfilePictureForAgent/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function assignPropertiesToAgent(data) {
  return fetch(baseUrl + "/Login/assignPropertiesToAgent/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function unassignPropertiesToAgent(data) {
  return fetch(baseUrl + "/Login/unassignPropertiesToAgent/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

//-------------------------
export function setLanguageForUser(data) {
  return fetch(baseUrl + "/Login/setLanguageForUser/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function linkIAMUser(data) {
  return fetch(baseUrl + "/Login/linkIAMUser/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
//--------Tickets--------

export function getMyTickets(data) {
  return fetch(baseUrl + "/Feedback/getMyTickets/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getTicketStatusList(data) {
  return fetch(baseUrl + "/Feedback/getTicketStatusList/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function createATicket(data) {
  return fetch(baseUrl + "/Feedback/createATicket/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getTicketDetails(data) {
  return fetch(baseUrl + "/Feedback/getTicketDetails/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function addCommentToATicket(data) {
  return fetch(baseUrl + "/Feedback/addCommentToATicket/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function addScreenshotsToATicket(data) {
  return fetch(baseUrl + "/Feedback/addScreenshotsToATicket/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getTicketListSubjects(data) {
  return fetch(baseUrl + "/Feedback/getTicketListSubjects/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function takeUserFeedback(data) {
  return fetch(baseUrl + "/Feedback/takeUserFeedback/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function addFileToATicket(data) {
  return fetch(baseUrl + "/Feedback/addFileToATicket/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

// ----------- CRM ---------------

export function crmGetProperties(data) {
  return fetch(baseUrl + "/Login/getPropertiesWithCommunication/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

// ----------- WL ---------------

export function getProfileForWLAdmin(data) {
  return fetch(baseUrl + "/Login/getProfileForWLAdmin/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getMenuItems(data) {
  return fetch(baseUrl + "/Login/getMenuItems/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function saveMenuItems(data) {
  return fetch(baseUrl + "/Login/saveMenuItems/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function registerUserForWL(data) {
  return fetch(baseUrl + "/Login/registerUserForWL/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function editWLDomain(data) {
  return fetch(baseUrl + "/Login/editWLDomain/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function checkDomainAvailabilityWithAWS(data) {
  return fetch(baseUrl + "/Login/checkDomainAvailabilityWithAWS/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getSectionItems(data) {
  return fetch(baseUrl + "/Login/getSectionItems/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function saveWLSectionItem(data) {
  return fetch(baseUrl + "/Login/saveWLSectionItem/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function saveWLSectionsOrder(data) {
  return fetch(baseUrl + "/Login/saveWLSectionsOrder/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function uploadWlFileBase64(data) {
  return fetch(baseUrl + "/Login/uploadWlFileBase64/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function uploadFileBase64(data) {
  return fetch(baseUrl + "/Login/uploadFileBase64/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function chooseTemplate(data) {
  return fetch(baseUrl + "/Login/chooseTemplate/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function uploadWlFile(data) {
  return fetch(baseUrl + "/Login/uploadWlFile/", {
    method: "POST", // POST for create, PUT to update when id already exists.
    body: data,
  })
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function saveWLService(data) {
  return fetch(baseUrl + "/Login/saveWLService/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getWLServices(data) {
  return fetch(baseUrl + "/Login/getWLServices/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getTemplates(data) {
  return fetch(baseUrl + "/Login/getTemplates/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getWLBasicConfig(data) {
  return fetch(baseUrl + "/Login/getWLBasicConfig/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getListOfContactedPeople(data) {
  return fetch(baseUrl + "/Login/getListOfContactedPeople/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function wlContactUs(data) {
  return fetch(baseUrl + "/Login/wlContactUs/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function markContactUsAsRead(data) {
  return fetch(baseUrl + "/Login/markContactUsAsRead/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function saveWLAboutUsMiddleman(data) {
  return fetch(baseUrl + "/Login/saveWLAboutUsMiddleman/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getWLAboutUsMiddleman(data) {
  return fetch(baseUrl + "/Login/getWLAboutUsMiddleman/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function createProject(data) {
  return fetch(baseUrl + "/Login/createProject/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getProjects(data) {
  return fetch(baseUrl + "/Login/getProjects/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getProjectDetails(data) {
  return fetch(baseUrl + "/Login/getProjectDetails/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function addOrEditProjectDetails(data) {
  return fetch(baseUrl + "/Login/addOrEditProjectDetails/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function uploadProjectImage(data) {
  return fetch(baseUrl + "/Login/uploadProjectImage/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function addWLModel(data) {
  return fetch(baseUrl + "/Login/addWLModel/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function editWLModel(data) {
  return fetch(baseUrl + "/Login/editWLModel/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getWLAboutUs(data) {
  return fetch(baseUrl + "/Login/getWLAboutUs/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function saveWLAboutUs(data) {
  return fetch(baseUrl + "/Login/saveWLAboutUs/", {
    method: "POST", // POST for create, PUT to update when id already exists.
    headers: {
      Accept: "application/json",
      "content-type": "text/html; charset=UTF-8",
    },
    body: JSON.stringify({
      ...data,
    }),
  })
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getDashboardWL(data) {
  return fetch(baseUrl + "/Login/getDashboardWL/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getWLProfile(data) {
  return fetch(baseUrl + "/Login/getWLProfile/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function saveWLProfile(data) {
  return fetch(baseUrl + "/Login/saveWLProfile/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getWLFooter(data) {
  return fetch(baseUrl + "/Login/getWLFooter/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function saveWLFooter(data) {
  return fetch(baseUrl + "/Login/saveWLFooter/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getEjarForm(data) {
  return fetch(baseUrl + "/Login/getEjarForm/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function saveEjarForm(data) {
  return fetch(baseUrl + "/Login/saveEjarForm/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function forgotPassword(data) {
  return fetch(baseUrl + "/Login/forgotPassword/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function verifyWLUser(data) {
  return fetch(baseUrl + "/Login/verifyWLUser/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getNationalAddress(data) {
  return fetch(baseUrl + "/Login/addressSearch/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

// export function subscribe(data) {
//   return fetch(baseUrl + "/Login/subscribe/", POST(data))
//     .then(res => handleResponse(res, data))
//     .catch(handleError);
// }
export function countVisit(data) {
  return fetch(baseUrl + "/Login/countVisit/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function verifyPhoneOwner(data) {
  return fetch(baseUrl + "/Login/verifyPhoneOwner/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function saveInterest(data) {
  return fetch(baseUrl + "/Login/saveInterest/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getDomainOwner(data) {
  return fetch(baseUrl + "/Login/getDomainOwner/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getUserPlan(data) {
  return fetch(baseUrl + "/Login/userPlan/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function canAddProperty(data) {
  return fetch(baseUrl + "/Login/canAddProperty/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function canAddAgent(data) {
  return fetch(baseUrl + "/Login/canAddAgent/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getNormalPlans(data) {
  return fetch(baseUrl + "/Login/getNormalPlans/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function setUserPlan(data) {
  return fetch(baseUrl + "/Login/setUserPlan/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getBrokerRegaInfo(data) {
  return fetch(baseUrl + "/Login/getBrokerRegaInfo/", POST(data))
    .then(async (response) => {
      if (response.ok) return response.json();
      if (response.status === 500) {
        const errorMessage = await response.text();
        fetch(baseUrl + '/Feedback/addServerError/', {
          method: "POST",
          headers: { Accept: "application/json", "content-type": "application/json" },
          body: JSON.stringify({
            status: response.status,
            url: response.url,
            payload: JSON.stringify(data),
            error_message: errorMessage,
            language: i18next.language === 'ar' ? '0' : '1'
          })
        });
      }
    })
    .catch(() => { });
}
export function getDomainInfo(data) {
  return fetch(baseUrl + "/Login/getDomainInfo/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function cancelUserPlan(data) {
  return fetch(baseUrl + "/Login/cancelUserPlan/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function cancelWLPlan(data) {
  return fetch(baseUrl + "/Login/cancel/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function saveCustomer(data) {
  return fetch(baseUrl + "/Customers/saveCustomer/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getCustomers(data) {
  return fetch(baseUrl + "/Customers/getCustomers/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getCustomer(data) {
  return fetch(baseUrl + "/Customers/getCustomer/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function deleteCustomer(data) {
  return fetch(baseUrl + "/Customers/deleteCustomer/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function changeUserStatus(data) {
  return fetch(baseUrl + "/Login/changeUserStatus/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getSimiliarCustomers(data) {
  return fetch(baseUrl + "/Customers/similiar/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function checkSaudi(data) {
  return fetch(baseUrl + "/Login/checkSaudi/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function renew(data) {
  return fetch(baseUrl + "/Orders/renew/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function upgradePlan(data) {
  return fetch(baseUrl + "/Login/upgradePlan/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function schedulePlan(data) {
  return fetch(baseUrl + "/Login/schedulePlan/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function saveBargain(data) {
  return fetch(baseUrl + "/Bargain/saveBargain/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function changeBargainStatus(data) {
  return fetch(baseUrl + "/Bargain/changeStatus/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getBargainProperties(data) {
  return fetch(baseUrl + "/Bargain/properties/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function deleteBargain(data) {
  return fetch(baseUrl + "/Bargain/deleteBargain/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getBargain(data) {
  return fetch(baseUrl + "/Bargain/getBargain/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getBargains(data) {
  return fetch(baseUrl + "/Bargain/getBargains/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function changeOrder(data) {
  return fetch(baseUrl + "/Bargain/changeOrder/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function saveBargainComment(data) {
  return fetch(baseUrl + "/Bargain/saveComment/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function changeTaskStatus(data) {
  return fetch(baseUrl + "/Task/changeStatus/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function saveTaskComment(data) {
  return fetch(baseUrl + "/Task/saveComment/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function changeTaskOrder(data) {
  return fetch(baseUrl + "/Task/changeOrder/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function deleteTask(data) {
  return fetch(baseUrl + "/Task/deleteTask/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getTasks(data) {
  return fetch(baseUrl + "/Task/getTasks/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function saveTask(data) {
  return fetch(baseUrl + "/Task/saveTask/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getTask(data) {
  return fetch(baseUrl + "/Task/getTask/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function editTask(data) {
  return fetch(baseUrl + "/Task/editTask/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function connectWithWhatsApp(data) {
  return fetch(baseUrl + "/whatsapp/qr/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function whatsAppLogout(data) {
  return fetch(baseUrl + "/whatsapp/logout/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function sendWhatsAppMessage(data) {
  return fetch(baseUrl + "/whatsapp/send/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function getCustomerProps(data) {
  return fetch(baseUrl + "/Customers/getCustomerProps/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
export function requestNafath(data) {
  return fetch(baseUrl + "/Login/requestNafath/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function checkNafath(data) {
  return fetch(baseUrl + "/Login/checkNafath/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

// export function acticatePlanTry(data) {
//   return fetch(baseUrl + "/Login/acticatePlanTry/", POST(data))
//     .then(res => handleResponse(res, data))
//     .catch(handleError);
// }
