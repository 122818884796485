import { Avatar, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loadingdata from '../../../components/global-components/loading-data';
import NoData from '../../../components/global-components/no-data';
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import { Link } from "react-router-dom";
import { getPropertiesWithTickets } from "../../../api/propertyApi";
import { getTicketStatusList } from "../../../api/userApi";
import { useTranslation } from "react-i18next";

const pageSize = 5;
const publicUrl = process.env.REACT_APP_URL + "/";

const AdsComplaintsPage = () => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const { token } = useAuth();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [statusArr, setStatusArr] = React.useState([]);
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    getTicketStatusList({ language: i18n.language === 'ar' ? '0' : '1' }).then(result => setStatusArr(result.response.ticketStatusArray));
  }, [i18n.language]);

  const getData = () => {
    getPropertiesWithTickets({ userId: token, language: i18n.language === 'ar' ? '0' : '1', page: page, pageSize: pageSize }).then(res => {
      if (res.resCode === 0) {
        const temp = [];

        res.response.propertyArray?.map(item => {
          for (let ticket of item.tickets) {
            temp.push({
              address: item.address,
              idToShare: item.idToShare,
              photos: item.photos,
              propertyType: item.propertyType,
              listedFor: item.listedFor,
              propertyTypeName: item.propertyTypeName,
              ticket: ticket[0]
            });
          }
          return null;
        });
        setData(temp);
        setTotalCount(res.response.totalCount);
      }
      setLoadingData(false);
    });
  };

  useEffect(() => {
    getData();
  },// eslint-disable-next-line
    [token, page, i18n.language]);
  
  
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  
  return (
    <>
      <SubHeader OKElement={<></>} />
      
      <TableContainer className="table-container mb-5" component={Paper}>
        <Table sx={{ minWidth: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell align="right">{t("RealEstateAD")}</TableCell>
              <TableCell align="right">{t("ReportSubject")}</TableCell>
              <TableCell align="right">{t("ReportStatus")}</TableCell>
              <TableCell align="right">{t("CreationDate")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingData ? <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            ><TableCell colSpan={4}><Loadingdata /></TableCell></TableRow> :
              data?.length > 0 ? data.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="right" component="th" scope="row">
                    <Link className="d-flex" style={{ gap: '7px' }} to={`/admin/property-details/${row.idToShare}`}>
                      <Avatar
                        sx={{ width: 65, height: 65, borderRadius: '5px' }}
                        variant="square"
                        src={row?.photos?.split(',')?.[0] ? row?.photos?.split(',')?.[0] :
                          `${publicUrl}assets/img/defimgs/${row.propertyType}.svg`} />
                      <div className="d-flex flex-column" style={{ gap: '5px' }}>
                          
                        <span style={{ textDecoration: 'underline' }}>{row.propertyTypeName + " " + (row.listedFor === "2" ? t("ForSale") : t("ForRent"))}</span>
                        <span>{row.address}</span>
                      </div>
                    </Link>
                     
                  </TableCell>
                  <TableCell align="right">
                    {row.ticket?.title}
                  </TableCell>
                  <TableCell align="right">
                    {statusArr.filter(i => i.status === row.ticket?.status)?.[0]?.name}
                  </TableCell>
                  <TableCell align="right">{row.ticket?.createdAt}</TableCell>

                </TableRow>
              )) :
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                ><TableCell colSpan={4}><NoData msg={t("ThereAreNoReportsOnYourRealEstateADs")} /></TableCell></TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      {totalCount > pageSize && (
        <Pagination
          sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '15px' } }}
          count={parseInt(Math.ceil(totalCount / pageSize))}
          page={page}
          onChange={handlePageChange}
          color="primary" />

      )}
    </>
  );
}

export default AdsComplaintsPage;
