import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import SubHeader from "../../../components/global-components/sub-header";
import { useTranslation } from "react-i18next";
import AllDealsReport from "./components/deals-reports/all-deals-report";
// import DealsPerStageReport from "./components/deals-reports/deals-per-stage-report";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

const ReportsPage = () => {

  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 
  return (
    <>
      <SubHeader OKElement={<></>} />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab wrapped label={t("DealsReports")} />
          </Tabs>

        </Box>

        <CustomTabPanel value={value} index={0}>
          <AllDealsReport />
          {/* <DealsPerStageReport/> */}
        </CustomTabPanel>
      </Box>
    </>
  );
}

export default ReportsPage;
