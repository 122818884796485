import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, InputAdornment, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { createProject } from "../../../api/userApi";
import GoogleMapsContainerForAdmin from "../../../components/admin/components/GoogleMapsComponentForAdmin";
import ADConstraints from "../../../components/ad-constraints";
import { confirmAlert } from "react-confirm-alert";
import RoomIcon from "@mui/icons-material/Room";
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import { getDemoSnakbar, preventString, resolvePersianAndArabicNumbers } from "../../../helpers";
import { DEMO_VENDORS } from "../../../constants";
import { useTranslation } from "react-i18next";

const AddProject = (props) => {
  const { token, vendor } = useAuth();
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const [newItem, setNewItem] = useState({
    language: i18n.language === 'ar' ? '0' : '1',
    userId: token,
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [ShowTerms, setShowTerms] = useState(true);
  const [ZoomLevel, setZoomLevel] = useState(14);

  function handleZoomChange(NewZoom) {
    setZoomLevel(NewZoom);
  }
  const options = {
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          <h3>{t("ConfirmAddress")}</h3>
          <h5>{t("TheProjectYouWantToAddIsLocatedIn")}</h5>
          <Alert severity='info' icon={<RoomIcon />}>
            {newItem.neighborhood}
          </Alert>

          <div className='btns-wrapper'>
            <button
              className='primary-btn px-4'
              onClick={() => {
                if (DEMO_VENDORS.includes(vendor)) {
                  setOpenDemoSnackBar(true);
                } else {
                  createProject({
                    ...newItem,
                    area: preventString(newItem.area),
                    totalUnits: preventString(newItem.totalUnits),
                  }).then((_items) => {
                    if (_items.resCode === 0) {
                      setCompletedSteps([0, 1]);
                      onClose();
                      navigate(`/admin/add-project/${_items.response.id}`);
                    }
                  });
                }
              }}>
              {t("Continue")}
            </button>
            <button
              className='outlined-btn px-3'
              onClick={() => {
                onClose();
                setCompletedSteps([0, 1]);
              }}>
              {t("ChangeAddress")}
            </button>
          </div>
        </div>
      );
    },
    closeOnEscape: true,
    closeOnClickOutside: true,
    willUnmount: () => { },
    afterClose: () => { },
    onClickOutside: () => { },
    onKeypressEscape: () => { },
    overlayClassName: "overlay-custom-class-name",
  };

  function getFormatedPrice(price) {
    if (price) {
      let value = price?.toString()?.replace(/[,]+/g, "");
      return value?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    } else
      return price;
  }

  const handleClickOpen = () => {
    if (
      newItem.latitude &&
      newItem.longitude &&
      newItem.totalUnits &&
      newItem.name &&
      newItem.area
    ) {
      if (ZoomLevel < 15) {
        toast.error(t("PleaseZoomInForPreciseLocation"));
      } else {
        confirmAlert(options);
      }
    } else {
      toast.error(t("PleaseFillInAllProjectData"));
    }
  };

  return (
    <div>
      <SubHeader
        withSteps
        steps={[t("RealEstateAdvertisingControls"), t("BasicInformation"), t("Specifications")]}
        OKElement={<></>}
        completedSteps={completedSteps}
        currentStep={currentStep}
      />

      {ShowTerms ? (
        <ADConstraints
          project
          onOK={() => {
            setCurrentStep(1);
            setCompletedSteps([0]);
            setShowTerms(false);
          }}
        />
      ) : (
        <>
          <div className='custom-card'>
            <div className='card-header'>
              <h4>{t("BasicInformation")}</h4>
              <h6>{t("SpecifyTheProjectNameAreaNumberOfUnitsAndExactGeographicalLocation")}</h6>
            </div>
            <div className='card-body pb-0'>
              <div className='fields-wrapper'>
                <div className='row p-0 m-0 col-12'>
                  <div className='col-12 col-md-4 mb-4'>
                    <TextField
                      label={t("ProjectName")}
                      sx={{ width: "100%" }}
                      required={true}
                      autoComplete="false"
                      inputProps={{ maxLength: 50 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={newItem.name}
                      size='small'
                      onChange={(v) => {
                        setNewItem({
                          ...newItem,
                          name: resolvePersianAndArabicNumbers(
                            v.currentTarget.value?.length >= 50 ? v.currentTarget.value?.substring(0, 50) : v.currentTarget.value
                          )
                        });
                      }}
                    />
                    <h6 className='rightalign mt-1'>
                      {newItem?.name
                        ? parseInt(newItem?.name.length) + "/50"
                        : "0/50"}
                    </h6>
                  </div>
                  <div className='col-12 col-md-4 mb-4'>
                    <TextField
                      label={t("ProjectArea")}
                      required={true}
                      autoComplete="false"
                      inputProps={{ maxLength: 15 }}
                      sx={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>{t("m2")}</InputAdornment>
                        ),
                      }}
                      size='small'
                      value={newItem.area ? getFormatedPrice(newItem.area) : newItem.area}
                      onChange={(v) => {
                        setNewItem({
                          ...newItem,
                          area: preventString(v.currentTarget.value?.replace(/,/g, ''))
                        });
                      }}
                    />
                  </div>
                  <div className='col-12 col-md-4 mb-4'>
                    <TextField
                      inputProps={{ maxLength: 15 }}
                      label={t("TotalUnits")}
                      required={true}
                      autoComplete="false"
                      value={newItem.totalUnits ? getFormatedPrice(newItem.totalUnits) : newItem.totalUnits}
                      onChange={(v) => {
                        setNewItem({
                          ...newItem,
                          totalUnits: preventString(v.currentTarget.value?.replace(/,/g, ''))
                        });
                      }}
                      sx={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size='small'
                    />
                  </div>
                </div>

                <div className='col-12'>
                  
                  <GoogleMapsContainerForAdmin
                    title={t("LocateTheProject")}
                    setLocation={(e) => {
                      setNewItem({
                        ...newItem,
                        neighborhood: e.neighborhood,
                        address: e.city && e.neighborhood ? e.city + ' - ' + e.neighborhood :
                          e.city ? e.city :
                            e.neighborhood
                        ,
                        address_ar: e.address_ar,
                        latitude: e.latitude,
                        longitude: e.longitude,
                        city: e.city
                      });
                    }}
                    onZoomChange={handleZoomChange}
                  />
                  <Alert severity='warning' style={{ width: "fit-content", margin: '-35px 0 15px', lineHeight: 1.9 }}>
                    &nbsp;&nbsp;{t("PleaseSpecifyTheLocationAccordingly")}
                  </Alert>
                </div>
              </div>
            </div>
            <div className='card-footer'>
              <button onClick={handleClickOpen} className='primary-btn px-5'>
                {t("Next")}
              </button>
            </div>
          </div>
        </>
      )}
      {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

    </div>
  );
}

export default AddProject;
