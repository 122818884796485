import { IconButton, Pagination, Paper, Collapse, Table, TableBody, TableContainer, TableHead, TableRow, Box, Avatar, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loadingdata from '../../../components/global-components/loading-data';
import NoData from '../../../components/global-components/no-data';
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import { connectWithWhatsApp, getCustomerProps, getUserProile, getWLProfile } from "../../../api/userApi";
import { toast } from "react-toastify";
import TableCell from "@mui/material/TableCell";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CustomerDetails from "./customer-details";
import SendWhatsAppMsg from "../../../components/send-whatsapp-msg";
import ActionsPopper from "../../../components/global-components/actions-popper";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from "react-i18next";
import { renderCustomerType, renderFeaturesForWhatsAppMsg, trimPhone } from "../../../helpers";
import LoadingButton from "../../../components/global-components/loading-btn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import md5 from "md5";

const pageSize = 10;
const interestedPageSize = 10;
const publicUrl = process.env.REACT_APP_URL + "/";

const Row = ({ setWhatsAppDialog, siteName, expendedRow, setExpendedRow, row, page, setRow, setItem, setAnchorEl, anchorEl, setOpenPopper, openPopper, setOpenCustomerDialog, setClient, companyName }) => {
  const { t } = useTranslation();
  const [similarPage, setSimailarPage] = useState([
    { key: 0, page: 1 }
  ]);
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  useEffect(() => {
    let temp = [];
    row.interests?.map(i => temp.push({ key: i.id, page: 1 }));
    setSimailarPage(temp);
  }, [row, page]);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, background: row?.property?.propertyId === expendedRow ? '#fafafa' : 'none' }}>
        <TableCell align="right" className="small-col">
          <IconButton
            aria-label="expand row"
            size="small"
            className="custom-btn"
            sx={{ background: row?.property?.propertyId === expendedRow ? 'var(--main-color-one)' : '#fff', borderRadius: '8px' }}
            onClick={() => setExpendedRow((old) => old === row?.property?.propertyId ? 0 : row?.property?.propertyId)}
          >
            {row?.property?.propertyId === expendedRow ? <KeyboardArrowUpIcon sx={{ color: '#fff' }} /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
       
        <TableCell align="right">
          <div className="d-flex align-items-center" style={{ gap: '5px' }}>
            <Avatar
              sx={{ width: 48, height: 48, borderRadius: '6px' }}
              variant="square"
              src={row.property?.photos.split(',')[0] ? row.property?.photos.split(',')[0] :
                `${publicUrl}assets/img/defimgs/${row.property?.propertyType}.svg`} />
            {row.property?.propertyTypeName} {row.property?.listedFor === '2' ? t("ForSale") : t("ForRent")}
          </div>
        </TableCell>
        <TableCell align="right">{row.property?.address}</TableCell>
        <TableCell align="right">{Intl.NumberFormat("en").format(row.property?.defaultPrice)}</TableCell>
        <TableCell align="right">{row.customers?.length}</TableCell>
      </TableRow>
      <TableRow sx={{ background: row?.property?.propertyId === expendedRow ? '#fafafa' : 'none' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={row?.property?.propertyId === expendedRow} timeout="auto" unmountOnExit>
           
            <Box sx={{ margin: 1 }}>
              <div style={{ display: 'flex', maxHeight: '400px', width: '100%', flexDirection: 'column', marginBottom: 30 }}>
                <p style={{ fontSize: '15px', fontWeight: '600', marginBottom: 25 }}>{`${t("InterestedList")} (${row?.customers?.length ? Intl.NumberFormat("en").format(row?.customers?.length) : '0'})`}</p>
                {row?.customers?.length > 0 && (
                  <LoadingButton
                    classes="primary-btn btn whatsapp-button px-3 mb-3 py-2"
                    style={{ width: 'fit-content', height: '40px' }}
                    handleClick={() =>
                      setWhatsAppDialog({
                        message: `السلام عليكم،\n\nمتوفّر لدينا ${row?.property?.propertyTypeName} ${row?.property?.listedFor === '1' ? t("ForRent") : t("ForSale")} في${row?.property?.fullAddress?.split(",")?.[1]}، حي${row?.property?.fullAddress?.split(",")?.[2]}\nالسعر: ${Intl.NumberFormat("en").format(row?.property.defaultPrice)} ريال${row?.property.listedFor === '1' ? `/${row?.property.defaultPriceType.name}`:''}\n\n${renderFeaturesForWhatsAppMsg(row?.property)}\nرقم ترخيص الإعلان: ${row?.property.additionalInfo?.filter(i=> i.key ==="10")?.[0]?.valueText || t("NotAvailable")}\nرابط الإعلان: ${window.location.origin}/property-details/${row?.property.idToShare}\n\n${siteName}\nرخصة فال: ${row?.property.additionalInfo?.filter(i=> i.key ==="21")?.[0]?.valueText || t("NotAvailable")}`,
                        numbers: row?.customers?.map(item => item.phone),
                        visible: true,
                        multiple: true,
                        customers: row?.customers
                      })
                    }
                    label={t("ContactAllViaWhatsApp")}
                    icon={<WhatsAppIcon />}
                  />
                )}
                <TableContainer className="table-container" component={Paper}>
                  <Table sx={{ minWidth: '100%' }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">{t("Name")}</TableCell>
                        <TableCell align="right">{t("PhoneNumber")}</TableCell>
                        <TableCell align="right">{t("Email")}</TableCell>
                        <TableCell align="right">{t("CustomerType")}</TableCell>
                        <TableCell align="right" style={{ maxWidth: '100px' }}> {t("Notes")}</TableCell>
                        <TableCell align="right">{t("Actions")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.customers?.length > 0 && row?.customers?.slice(0).splice((similarPage.filter(p => p.key === row.property.propertyId)?.[0]?.page * interestedPageSize)
                        - interestedPageSize, interestedPageSize)?.map((row1) => (
                          <TableRow
                            key={row1.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="right">
                              <span style={{ textDecoration: 'underline', color: 'var(--main-color-one)', cursor: 'pointer' }} onClick={() => {
                                setClient(row1);
                                setOpenCustomerDialog(true);
                              }}>{row1.name}</span>
                            </TableCell>
                            <TableCell align="right"><a rel="noreferrer" target={isLargeScreen ? '_blank' : "_self"} href={`tel:${trimPhone(row1.phone)}`} style={{ color: 'var(--main-color-one)', textDecoration: 'underline',direction:'ltr',display:'inline-block' }}>{trimPhone(row1.phone)}</a></TableCell>
                            <TableCell align="right">{row1.email || t("Undefined")}</TableCell>
                            <TableCell align="right">{renderCustomerType(row1.type) || t("Undefined")}</TableCell>
                            <TableCell align="right">
                              <p
                                title={row1.notes ? row1.notes.length > 70 ? row1.notes : '' : ''}
                                className='text-more'
                                style={{ whiteSpace: "pre-line" }}>
                                {row1.notes
                                  ? row1.notes.length > 70
                                    ? row1.notes.substr(0, 70) + "... "
                                    : row1.notes : t("Undefined")}
                              </p>
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                onClick={(e) => {
                                  setOpenPopper(!openPopper);
                                  setAnchorEl(anchorEl ? null : e.target);
                                  setRow(row1);
                                  setItem(row.property);
                                }}
                                sx={{
                                  background: '#fff',
                                  zIndex: 99,
                                  color: 'var(--main-color-one)',
                                  boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                                  '&:hover,&:focus': {
                                    color: '#fff',
                                    background: 'var(--main-color-one)'
                                  }
                                }}>
                                <MoreVertIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
                
               
              {row?.customers?.length > interestedPageSize && (
                <Pagination
                  sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '15px' } }}
                  count={parseInt(Math.ceil(row?.customers?.length / interestedPageSize))}
                  page={similarPage?.filter(p => p.key === row.property.propertyId)?.[0]?.page}
                  onChange={(event, value) => {
                    let temp = [];
                    similarPage?.map(p => p.key === row.property.propertyId ? temp.push({ key: row.property.propertyId, page: value }) : temp.push(p));
                    setSimailarPage(temp);
                  }}
                  color="primary" />
              )}
              <div style={{ width: '100%', height: 1, backgroundColor: '#ededed', marginTop: 15, marginBottom: 20 }} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const InterestedCustomersPage = () => {
  const [data, setData] = useState([]);
  const [whatsAppDialog, setWhatsAppDialog] = useState({
    visible: false,
    message: '',
    numbers: [],
    multiple: false,
    customers: []
  });
  const [loadingData, setLoadingData] = useState(true);
  const { token, AccountType, UserType, vendor } = useAuth();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
  const [client, setClient] = useState({});
  const [companyName, setCompanyName] = React.useState("");
  const [expendedRow, setExpendedRow] = useState(0);
  const [row, setRow] = useState();
  const [item, setItem] = useState();
  const { t, i18n } = useTranslation();
  const [openPopper2, setOpenPopper2] = useState(false);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [siteName, setSiteName] = useState('');
  const [superToken, setSuperToken] = useState('');

  useEffect(() => {
    connectWithWhatsApp({ userId: token, language: i18n.language === 'ar' ? '0' : '1' });
  }, [token, i18n.language]);

  useEffect(() => {
    if (token && AccountType === "2") {
      getUserProile({ userId: token, language: i18n.language === 'ar' ? '0' : '1' }).then(user => {
        if (user.resCode === 0) {
          setCompanyName(user.response.userProfile.companyName);
          if (UserType === "5") {
            setSuperToken(md5(user.response.userProfile.superUserCreatedAt + user.response.userProfile.superUserId?.[0]));
          }
        }
      });
    }
    getWLProfile({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        setSiteName(window.location.href.includes('amakkncompany.com') ? 'مكتب أحمد للعقارات (مثال)' : _userInfo.response.wlUser.briefDescription);
      }
    });
  },// eslint-disable-next-line
    [token, AccountType, i18n.language]);
  
  const getData = async () => {
    setLoadingData(true);
    getCustomerProps({
      page: page,
      language: i18n.language === 'ar' ? '0' : '1',
      userId: UserType === "5" ? superToken : token
    }).then((res) => {
      if (res.resCode === 0) {
        setData(res.response.array);
        setTotalCount(res.response.totalCount);
      } else {
        toast.error(res.resStr);
      }
    }).finally(() => setLoadingData(false));
  };

  useEffect(() => {
    if (UserType !== '5' || (UserType === "5" && superToken))
      getData();
  },// eslint-disable-next-line
    [token, page, superToken, UserType, i18n.language]);
  
  
  const handlePageChange = (event, value) => {
    setPage(value);
  };
 

  return (
    <>
      <SubHeader OKElement={<></>} />
     
      <div style={{ display: 'flex', minHeight: '500px', width: '100%', flexDirection: 'column', marginBottom: '50px' }}>
       
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
          <TableContainer className="table-container" component={Paper}>
            <Table sx={{ minWidth: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell align="right" className="small-col" />
                  <TableCell align="right">{t("Property")}</TableCell>
                  <TableCell align="right">{t("PropertyAddress")}</TableCell>
                  <TableCell align="right">{t("PropertyPrice")} ({t("SAR")})</TableCell>
                  <TableCell align="right">{t("InterestedCount")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {loadingData ?
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell colSpan={5}><Loadingdata /></TableCell>
                  </TableRow> : data.length > 0 ?
                    data.map((row) => (
                      <Row
                        key={row?.property?.propertyId}
                        row={row}
                        expendedRow={expendedRow}
                        setExpendedRow={setExpendedRow}
                        page={page}
                        companyName={companyName}
                        setOpenCustomerDialog={setOpenCustomerDialog}
                        setRow={setRow}
                        setItem={setItem}
                        setOpenPopper={setOpenPopper2}
                        openPopper={openPopper2}
                        setAnchorEl={setAnchorEl2}
                        anchorEl={anchorEl2}
                        setWhatsAppDialog={setWhatsAppDialog}
                        siteName={siteName}
                        setClient={setClient} />
                    )) :
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    ><TableCell colSpan={5}><NoData msg={t("NoCustomersFound")} /></TableCell></TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          <SendWhatsAppMsg
            dialog={whatsAppDialog}
            setDialog={setWhatsAppDialog}
          />
        </div>
            
        {totalCount > pageSize && (
          <Pagination
            sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '25px', justifyContent: 'start' } }}
            count={parseInt(Math.ceil(totalCount / pageSize))}
            page={page}
            onChange={handlePageChange}
            color="primary" />
        )}
      </div>
          
      <CustomerDetails
        openCustomerDialog={openCustomerDialog}
        setOpenCustomerDialog={setOpenCustomerDialog}
        setClient={setClient}
        companyName={companyName}
        client={client} />
          

      <ActionsPopper
        openPopper={openPopper2}
        anchorEl={anchorEl2}
        setAnchorEl={setAnchorEl2}
        setOpenPopper={setOpenPopper2}
        items={
          [
            {
              title: t("ContactViaWhatsApp"),
              action: () => setWhatsAppDialog({
                numbers: [row.phone],
                message: `السيد ${row.name}،\nالسلام عليكم،\n\nمتوفّر لدينا ${item.propertyTypeName} ${item.listedFor === '1' ? t("ForRent") : t("ForSale")} في${item?.fullAddress?.split(",")?.[1]}، حي${item?.fullAddress?.split(",")?.[2]}\nالسعر: ${Intl.NumberFormat("en").format(item.defaultPrice)} ريال${item.listedFor === '1' ? `/${item.defaultPriceType.name}`:''}\n\n${renderFeaturesForWhatsAppMsg(item)}\nرقم ترخيص الإعلان: ${item.additionalInfo?.filter(i=> i.key ==="10")?.[0]?.valueText || t("NotAvailable")}\nرابط الإعلان: ${window.location.origin}/property-details/${item.idToShare}\n\n${siteName}\nرخصة فال: ${item.additionalInfo?.filter(i=> i.key ==="21")?.[0]?.valueText || t("NotAvailable")}`,
                visible: true,
                multiple: false,
                customers: []
              })
            },
            // {
            //   title: "اتصال مباشر",
            //   action: () => window.open(`tel:${trimPhone(row.phone)}`, '_blank')
            // },
            row?.email && {
              title: t("ContactViaEmail"),
              action: () => window.open(`mailto:${row.email}?subject=${companyName}&body=السيد ${row.name},\nالسلام عليكم,\n\nمتوفّر لدينا ${item.propertyTypeName} ${item.listedFor === '1' ? t("ForRent") : t("ForSale")} في${item?.fullAddress?.split(",")?.[1]}، حي${item?.fullAddress?.split(",")?.[2]}\nالسعر: ${Intl.NumberFormat("en").format(item.defaultPrice)} ريال${item.listedFor === '1' ? `/${item.defaultPriceType.name}`:''}\n\n${renderFeaturesForWhatsAppMsg(item)}\nرقم ترخيص الإعلان: ${item.additionalInfo?.filter(i=> i.key ==="10")?.[0]?.valueText || t("NotAvailable")}\nرابط الإعلان: ${window.location.origin}/property-details/${item.idToShare}\n\n${siteName}\nرخصة فال: ${item.additionalInfo?.filter(i=> i.key ==="21")?.[0]?.valueText || t("NotAvailable")}`, '_blank')
            }
          ]
        }
      />
    </>
  );
}

export default InterestedCustomersPage;
