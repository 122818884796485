import { Autocomplete, Box, Chip, ClickAwayListener, Divider, FormControl, Grow, MenuItem, Paper, Popper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../../helpers/context";
import LoadingData from "../../../../../components/global-components/loading-data";
import NoData from "../../../../../components/global-components/no-data";
import LoadingButton from "../../../../../components/global-components/loading-btn";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { getBargainsData } from "../../../../../api/reports-api";
import { toast } from "react-toastify";
import { CITIES, DISTRICTS, REGIONS } from "../../../../../constants";
import { getAgentsForCompany, getUserProile } from "../../../../../api/userApi";
import TuneIcon from '@mui/icons-material/Tune';
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

const AllDealsReport = () => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [isExportingData, setIsExportingData] = useState(false);

  const { token, AccountType, UserType, UserId } = useAuth();
  const [totalCount, setTotalCount] = useState(0);
  const { t, i18n } = useTranslation();
  const [agents, setAgents] = useState([]);
  const [userName, setUserName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [openPopper, setOpenPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    status: 'all',
    region: '',
    city: '',
    neighbourhood: [],
    added_by: "all",
    updatedAt: 'all'
  });

  const regionsOptions = useMemo(
    () => {
      return REGIONS.map((region) => ({
        id: +region.REGION_ID,
        label: i18n.language === "ar" ? region.REGIONNAME_AR : region.REGIONNAME_EN,
      }));
    }, // eslint-disable-next-line
    [i18n.language]);
  
  const citiesOptions = useMemo(
    () => {
      return CITIES.map((city) => ({
        id: +city.CITY_ID,
        label: i18n.language === "ar" ? city.CITYNAME_AR : city.CITYNAME_EN,
        regionId: +city.REGION_ID,
      }))
    }, // eslint-disable-next-line
    [i18n.language]);

  const districtsOptions = useMemo(
    () => {
      return DISTRICTS.map((district) => ({
        id: +district.DISTRICT_ID,
        label: i18n.language === "ar" ? district.DISTRICTNAME_AR : district.DISTRICTNAME_EN,
        regionId: +district.REGION_ID,
        cityId: +district.CITY_ID,
      }));
    }, // eslint-disable-next-line
    [i18n.language]);
  
  const getData = () => {
    setLoadingData(true);
    getBargainsData({
      userId: token,
      language: i18n.language === 'ar' ? '0' : '1',
      search: search?.startsWith('05') ? search?.substring(1) : search,
      status: filters.status === 'all' ? undefined : filters.status,
      // budgetEnd: filters.budgetEnd,
      region: filters.region,
      city: filters.city,
      neighbourhood: filters.neighbourhood,
      // budgetFrom: filters.budgetFrom,
      added_by: filters.added_by === "all" ? undefined : filters.added_by,
      updatedAt: filters.updatedAt === "all" ? '' : filters.updatedAt
    }).then((res) => {
      if (res.resCode === 0) {
        setData(res.response);
        setTotalCount(res.response.length);
      } else {
        toast.error(res.resStr);
      }
    }).finally(() => setLoadingData(false));
  };

  useEffect(() => {
    if (token && AccountType === "2" && UserType !== "5") {
      getUserProile({ userId: token, language: i18n.language === 'ar' ? '0' : '1' }).then(user => {
        if (user.resCode === 0) {
          setUserName(user.response.userProfile.name);
          setCompanyName(user.response.userProfile.companyName);
        }
      });
      getAgentsForCompany({ superUserId: token, language: i18n.language === 'ar' ? '0' : '1' }).then((users) => {
        setAgents(users.response.userArray);
      });
    }
      
  },// eslint-disable-next-line
    [token, AccountType, i18n.language]);
  
  const exportToExcel = (data) => {
    setIsExportingData(true);
    return new Promise(function (resolve, reject) {
      var req = new XMLHttpRequest();
      var url = '/assets/excel-templates/ALL_DEALS.xlsx';
      req.open("GET", url, true);
      req.responseType = "arraybuffer";
      req.onreadystatechange = function () {
        if (req.readyState === 4) {
          if (req.status === 200) {
            resolve(XlsxPopulate.fromDataAsync(req.response));
          } else {
            reject("Received a " + req.status + " HTTP code.");
          }
        }
      };
      req.send();
    })
      .then(function (workbook) {
        let i = 8;
        let totalValues = data.reduce((accumulator, current) => accumulator + (parseInt(current.value) || 0), 0);
        let totalCommission = data.reduce((accumulator, current) => accumulator + parseInt(current.commission || 0), 0);
     
        workbook.sheet(0).cell('A2').value(`${t("AllDeals")}`);
        workbook.sheet(0).cell('A6').value(`${t("DealsCount")}: ${data.length}`);
        workbook.sheet(0).cell('C6').value(`${t("TotalValueOfDeals")}: ${Intl.NumberFormat("en").format(totalValues)} ${t("SAR")}`);
        workbook.sheet(0).cell('E6').value(`${t("TotalCommission")}: ${Intl.NumberFormat("en").format(totalCommission)} ${t("SAR")}`);

        if (data && data.length > 0) {
          data.map(line => {
            workbook.sheet(0).cell(`A${i}`).value(line?.buyerObject?.name || t("NotAvailable"));
            workbook.sheet(0).cell(`B${i}`).value(line?.property || line?.sellerObject?.name || t("NotAvailable"));
            workbook.sheet(0).cell(`C${i}`).value(line?.status === "0" ? t("NewDeal") :
              line?.status === "1" ? t("Negotiations") :
                line?.status === "2" ? t("AdvancedNegotiations") :
                  line?.status === "3" ? t("DealClosing") :
                    t("DealLosing")
            );
            workbook.sheet(0).cell(`D${i}`).value(line?.neighbourhoods?.length > 0 ?
              line?.neighbourhoods?.map((i, index) => `${regionsOptions.filter((opt) => +line?.region === +opt.id)?.[0]?.label} - ${citiesOptions.filter((opt) => +line?.city === +opt.id)?.[0]?.label} - ${districtsOptions.filter((opt) => +i === +opt.id)?.[0]?.label}`)?.join('\n')
              : t("Undefined"));
                  
            workbook.sheet(0).cell(`E${i}`).value(Intl.NumberFormat("en").format(line?.value.endsWith('.000') ? parseInt(line?.value) : line?.value) + ` ${t("SAR")}`);
            workbook.sheet(0).cell(`F${i}`).value(line?.added_by === companyName ? t("MainAccount") : line?.added_by);
            i++;
            return i;
          });
        }
        return workbook.outputAsync();
      })
      .then(function (blob) {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = `${t("AllDeals")}.xlsx`;
        setIsExportingData(false);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        toast.success(t("TheFileWasExportedSuccessfully"));
      })
      .catch(function (err) {
        console.log(err);
        toast.error(t("TheFileWasNotExported"));
        setIsExportingData(false);
      });
  };

  const hasFilter = () => {
    return filters.updatedAt !== 'all' || filters.added_by !== "all" || filters.status !== "all" || filters.region || filters.city || filters.neighbourhood?.length > 0;
  }

  const renderFilterationBox = () => {
    return (
      <Box sx={{ backgroundColor: '#fff', width: '300px', display: 'flex', flexDirection: 'column', px: 2, pt: 2 }}>
        <div className="filters-box">
          <h6 className="mt-2">{t("Stage")}</h6>
          <div className="col-12 mb-3 p-0">
            <FormControl className="custom-select">
              <Select
                value={filters.status}
                sx={{ width: "100%" }}
                inputProps={{
                  name: "updatedAt",
                  id: "updatedAt",
                }}
                onChange={(event) => {
                  setFilters({
                    ...filters,
                    status: event.target.value,
                  });
                }}
              >
                <MenuItem value='all'>{t("All")}</MenuItem>
                <MenuItem value='0'>{t("NewDeal")}</MenuItem>
                <MenuItem value='1'>{t("Negotiations")}</MenuItem>
                <MenuItem value='2'>{t("AdvancedNegotiations")}</MenuItem>
                <MenuItem value='3'>{t("DealClosing")}</MenuItem>
                <MenuItem value='4'>{t("DealLosing")}</MenuItem>
              </Select>
            </FormControl>
          </div>
  
          {AccountType === '2' && agents?.length > 0 && UserType !== "5" && (
            <>
              <Divider variant="inset" className="w-100" />
              <h6 className="mt-2">{t("AddedBy")}</h6>
              <div className="col-12 mb-3 p-0">
                <FormControl className="custom-select">
                  <Select
                    value={filters.added_by}
                    sx={{ width: "100%" }}
                    inputProps={{
                      name: "agentId",
                      id: "agentId",
                    }}
                    onChange={(event) => {
                      setFilters({
                        ...filters,
                        added_by: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value='all' key="all">{t("All")}</MenuItem>
                    <MenuItem value={UserId} key="mother-account">{userName === companyName ? t("MainAccount") : userName}</MenuItem>
                    {agents?.map(agent => <MenuItem value={agent.userId} key={agent.userId}>{agent.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </div>
            </>
          )}
          <Divider variant="inset" className="w-100" />
          <h6 className="mt-2">{t("UpdatedAt")}</h6>
          <div className="col-12 mb-3 p-0">
            <FormControl className="custom-select">
              <Select
                value={filters.updatedAt}
                sx={{ width: "100%" }}
                inputProps={{
                  name: "updatedAt",
                  id: "updatedAt",
                }}
                onChange={(event) => {
                  setFilters({
                    ...filters,
                    updatedAt: event.target.value,
                  });
                }}
              >
                <MenuItem value='all'>{t("All")}</MenuItem>
                <MenuItem value='month'>{t("AMonthAgo")}</MenuItem>
                <MenuItem value='quarter'>{t("3MonthsAgo")}</MenuItem>
                <MenuItem value='year'>{t("AYearAgo")}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Divider variant="inset" className="w-100" />
          <h6 className="mt-2">{t("Location")}</h6>
          <div className="col-12 mb-3 mt-3 p-0">
            <FormControl sx={{ width: "100%" }}>
              <Autocomplete
                // disablePortal
                size='small'
                slotProps={{
                  popper: {
                    sx: {
                      zIndex: 9999999999999
                    }
                  }
                }}
                options={regionsOptions}
                value={regionsOptions.filter(
                  (i) => +i.id === +filters?.region
                )?.[0] || null}
                sx={{ width: "100%" }}
                onChange={(v, newValue) => {
                  setFilters({
                    ...filters,
                    region: newValue?.id || "",
                    neighbourhood: [],
                    city: ''
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant='outlined'
                    label={t("Region")}
                  />
                )}
              />
   
            </FormControl>
            <FormControl sx={{ width: "100%", mt: 2 }}>
              <Autocomplete
                // disablePortal
                size='small'
                disabled={!filters?.region}
                options={citiesOptions.filter(
                  (i) => +i.regionId === +filters?.region
                )}
                value={
                  citiesOptions.filter(
                    (i) => +i.id === +filters?.city
                  )?.[0] || null
                }
                slotProps={{
                  popper: {
                    sx: {
                      zIndex: 9999999999999
                    }
                  }
                }}
                sx={{ width: "100%" }}
                noOptionsText={t("ThereAreNoCitiesAssociatedWithTheSelectedRegion")}
                onChange={(event, newValue) => {
                  setFilters({
                    ...filters,
                    city: newValue?.id || "",
                    neighbourhood: [],
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant='outlined'
                    label={t("City")}
                  />
                )}
              />
   
            </FormControl>
            <FormControl sx={{ width: "100%", mt: 2 }}>
              <Autocomplete
                // disablePortal
                size='small'
                multiple
                disabled={!filters?.city}
                options={districtsOptions.filter(
                  (i) =>
                    +i.cityId === +filters?.city &&
                    +i.regionId === +filters?.region
                )}
                slotProps={{
                  popper: {
                    sx: {
                      zIndex: 9999999999999
                    }
                  }
                }}
                value={
                  districtsOptions.filter(
                    (i) => filters?.neighbourhood?.includes(+i.id)
                  ) || []
                }
                sx={{ width: "100%" }}
                noOptionsText={t("ThereAreNoNeighborhoodsAssociatedWithTheSelectedCity")}
                onChange={(event, newValue) => {
                  const ids = newValue?.map(i => i.id);
                  setFilters({
                    ...filters,
                    neighbourhood: ids,
                  });
     
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant='outlined'
                    label={t("Neighborhood")}
                  />
                )}
              />
  
            </FormControl>
          </div>
        </div>
        <div className="col-12 mb-3 p-0 mt-3 mx-0 row justify-content-center" style={{ gap: 15 }}>
         
          <button
            className={`btn action-btn primary-btn`}
            onClick={() => {
              setFilters({
                status: 'all',
                region: '',
                city: '',
                neighbourhood: [],
                added_by: "all",
                updatedAt: 'all'
              });
            }
            }
          >{t("Reset")}</button>
        </div>
      </Box>
    );
  }
  
  const resolveStatusName = (status) => {
    const badge = (statusName) => {
      return <Chip label={statusName} size="medium" style={{
        color: status === '0' ? 'gold' : status === '1' ? "blue" : status === '2' ? 'green' : status === '3' ? 'black' : '#d50000',
        borderColor: status === '0' ? 'gold' : status === '1' ? "blue" : status === '2' ? 'green' : status === '3' ? 'black' : '#d50000',
        background: '#fff',
        borderWidth: '1px',
        borderStyle: 'solid'
      }} />;
    };
      
    switch (status) {
      case '0': return badge(t("NewDeal"));
      case '1': return badge(t("Negotiations"));
      case '2': return badge(t("AdvancedNegotiations"));
      case '3': return badge(t("DealClosing"));
      case '4': return badge(t("DealLosing"));
      default: return '';
    }
  }
  
  useEffect(() => {
    getData();
  },// eslint-disable-next-line
    [token, search, filters, UserType, i18n.language]);
  
  return (
    <>
      <div className="custom-card with-switcher">
        <div className="card-header flex-column flex-sm-row">
          <div>
            <h6>{t("AllDeals")} {totalCount ? ` (${totalCount})` : ''}</h6>
          </div>
          <div>
            
            {data && data?.length > 0 && <LoadingButton
              loading={isExportingData}
              classes="primary-btn btn export-btn px-3 py-3"
              style={{ width: 'fit-content' }}
              handleClick={() => exportToExcel(data)}
              label={t("ExportToExcel")}
              icon={<InsertDriveFileIcon />}
            />}
      
          </div>
        </div>
      
        <div className="card-body">
          <div
            style={{ width: "100%", alignItems: 'center', display: "flex", gap: 15, marginBottom: 15 }}>
            <TextField
              id='standard-search'
              style={{ minWidth: "60%", width: '100%', maxWidth: 'calc(100% - 140px)', background: '#fff' }}
              placeholder={t("SearchByNamePhoneNumberOrADLicense")}
              type='search'
              size='small'
              value={search}
              variant='outlined'
              onChange={(e) => {
                setSearch(e.currentTarget.value);
              }}
            />
            {data && data?.length > 0 &&
              <button className={`btn ${hasFilter() ? 'primary-btn' : 'outlined-btn'} action-btn`} style={{ width: '20%', minWidth: 120, maxWidth: 140 }} onClick={(e) => {
                setOpenPopper(!openPopper);
                setAnchorEl(anchorEl ? null : e.target);
              }}>
                {t("Filter")} <TuneIcon />
              </button>}
          
            <Popper
              sx={{
                zIndex: 100,
                offset: '55px 0 0 5px',
              }}
              open={openPopper}
              role={undefined}
              transition
              anchorEl={anchorEl}
              disablePortal={false}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={(event) => {
                      if (event.target.nodeName === 'BODY' && event.type === 'click') {
                        return;
                      } else {
                        setAnchorEl(null); setOpenPopper(false);
                      }
                       
                    }}>
                      {renderFilterationBox()}
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
          <div style={{ display: 'flex', height: data.length > 0 ? '420px' : '370px', width: '100%' }}>

            <TableContainer className="table-container responsive-table mb-5" component={Paper}>
              <Table sx={{ minWidth: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t("Buyer")}</TableCell>
                    <TableCell align="center">{t("SellerRealEstateAD")}</TableCell>
                    <TableCell align="center">{t("CurrentStage")}</TableCell>
                    <TableCell align="center">{t("Location")}</TableCell>
                    <TableCell align="center">{t("DealValue")}</TableCell>
                    {AccountType === '2' && <TableCell align="center">{t("AddedBy")}</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingData ? <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  ><TableCell colSpan={AccountType === '2' ? 7 : 6}><LoadingData /></TableCell></TableRow> :
                    data?.length > 0 ? data.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="center">
                          {row.buyerObject?.name}
                        </TableCell>
                        <TableCell align="center">
                          {row.property || row?.sellerObject?.name}
                        </TableCell>
                        <TableCell align="center">{resolveStatusName(row.status)}</TableCell>
                        <TableCell align="center">
                          {row?.neighbourhoods?.length > 0 ? <Stack direction="column" justifyContent="center" flexWrap={'wrap'} sx={{ gap: 1 }}>
                            {row?.neighbourhoods?.map((i, index) => <Chip key={index} label={
                              `${regionsOptions.filter((opt) => +row?.region === +opt.id)?.[0]?.label} - ${citiesOptions.filter((opt) => +row?.city === +opt.id)?.[0]?.label} - ${districtsOptions.filter((opt) => +i === +opt.id)?.[0]?.label}`} variant="outlined" />)}
                          </Stack>
                            : t("Undefined")}
                        </TableCell>
                        <TableCell align="center">{Intl.NumberFormat("en").format(row.value.endsWith('.000') ? parseInt(row.value) : row.value) + ` ${t("SAR")}`}</TableCell>
                        {AccountType === '2' && <TableCell align="center">
                          {row.added_by === companyName ? t("MainAccount") : row.added_by}
                        </TableCell>}
                       

                      </TableRow>
                    )) :
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell colSpan={AccountType === '2' ? 7 : 6}>
                          <NoData msg={t("NoDealsFound")} />
                        </TableCell>
                      </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    
    </>
  );
}

export default AllDealsReport;
