import React, { useState, useEffect } from "react";
import Banner from "./section-components/banner";
import BannerV2 from "./section-components/banner-v2";
import Bannerv3 from "./section-components/banner-v3";
// import Bannerv4 from "./section-components/banner-v4";
import Dream from "./section-components/dream";
import { getSectionItems } from "../api/userApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import Process from "./section-components/process";
import DownloadBanner from "./section-components/download-banner";
import YoutubeSection from "./section-components/youtube-section";
import AppLayout from "./layouts/app-layout";
import WhyUsSection from "./section-components/why-us-section";
import FeaturedProjects from "./section-components/featured-projects";
import TeamSection from "./section-components/team-section";
import FeaturedPropertiesSection from "./section-components/featured-properties-section";
import Featured from "./section-components/featured-properties";
import GoToTop from "./global-components/go-to-top";
import { useAuth } from "../helpers/context";
import TwitterSection from "./section-components/twitter-section";
import { DARK_TEMPLATES, DEMO_VENDORS, MODERN_TEMPLATES, CLASSIC_TEMPLATES, CLASSIC2_TEMPLATES, MODERN2_TEMPLATES } from "../constants";
import Banners from "./section-components/banners";
import { useTranslation } from "react-i18next";
// import RegionsBanner from "./section-components/regions-banner";

const Home_V1 = () => {
  const { vendor, templateId, currentPlan, setMapKey } = useAuth();
  const history = useNavigate();
  const [UserSection, setUserSection] = useState([]);
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (vendor !== 'amakkn' && searchParams.get("mapkey")) {
      setMapKey(searchParams.get("mapkey"));
    }
  },// eslint-disable-next-line
    [vendor, searchParams]);

  useEffect(() => {
    if (vendor) {
      if (window.location.hash) {
        let linkkk = window.location.href.split("#")[1];
        linkkk = linkkk.substring(1, linkkk.length);
        history(linkkk);
      }
      if (vendor !== "amakkn") {
        if (DEMO_VENDORS.includes(vendor) && localStorage.getItem(`sections-${vendor}`)) {
          setUserSection(JSON.parse(localStorage.getItem(`sections-${vendor}`)));
        } else {
          getSectionItems({ userName: vendor, language: i18n.language === 'ar' ? '0' : '1' }).then(
            (_userSection) => {
              if (_userSection.resCode === 0) {
                setUserSection(_userSection.response);
              } else {
                history("/error");
              }
            }
          );
        }
      } else {
        setUserSection([
          {
            key: "1",
            loginRequired: "0",
            name: "search",
            isVisible: "1",
            details: {
              title1: "",
              title2: t("WeHelpYouFindTheRightPlace"),
            },
          },
          {
            key: "2",
            loginRequired: "0",
            name: "features",
            isVisible: "1",
            details: {
              title: t("FeaturedRealEstateADs"),
            },
          },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor]);

  return (
    UserSection.length > 0 && (
      <AppLayout withFooter>
        {UserSection.map(
          (section, i) =>
          ({
            search: section.isVisible !== "0" && (
              <Banner data={section.details} key={section.key} />
            ),
            features:
              vendor === "amakkn" ? (
                <div key={section.key}>
                  <Banners />
                  <Featured data={section.details} key={section.key} />
                </div>
              ) : (
                section.isVisible !== "0" && (
                  <FeaturedPropertiesSection
                    data={section.details}
                    key={section.key}
                  />
                )
              ),
            // brokerAbout: section.isVisible !== "0" && section.details.content?.trim() !=="" && (
            //   <Process
            //     type='broker'
            //     data={section.details}
            //     key={section.key}
            //   />
            // ),
            services: section.isVisible !== "0" && (
              <Dream data={section.details} key={section.key} />
            ),
            brokerSearch: section.isVisible !== "0" && (
              <Bannerv3 data={section.details} key={section.key} />
            ),
            whyUs: section.isVisible !== "0" && (
              <WhyUsSection data={section.details} key={section.key} />
            ),
            youtube: [...DARK_TEMPLATES, ...MODERN_TEMPLATES, ...CLASSIC_TEMPLATES, ...CLASSIC2_TEMPLATES, ...MODERN2_TEMPLATES].includes(+templateId) ? null : section.isVisible !== "0" &&
              section.details.link?.trim() !== "" ? (
              <YoutubeSection data={section.details} key={section.key} />
            ) : null,
            team: section.isVisible !== "0" && (
              <TeamSection data={section.details} key={section.key} />
            ),
            // callUs: section.isVisible !== "0" && (
            //   <CallUSSection key={section.key} />
            // ),
            // brokerLocation: section.isVisible !== "0" &&
            //   section.details.lat !== "" && (
            //     <BrokerLocation
            //       sectionName={section.nameAr}
            //       data={section.details}
            //       key={section.key}
            //     />
            //   ),
            featuredProjects: section.isVisible !== "0" &&
              section.details?.projectsId !== "" && (
                <FeaturedProjects
                  sectionName={section.nameAr}
                  data={section.details}
                  key={section.key}
                />
              ),
            // intro: section.isVisible !== "0" && <INTRO key={section.key} />,
            // importantProject: section.isVisible !== "0" && (
            //   <Bannerv4 data={section.details} key={section.key} />
            // ),
            companySearch: section.isVisible !== "0" && (
              <BannerV2 data={section.details} key={section.key} youtubeLink={UserSection?.filter(i => i.name === "youtube")?.[0]?.isVisible !== "0" ? UserSection?.filter(i => i.name === "youtube")?.[0]?.details.link?.trim() : ''} />
            ),
            about: !MODERN_TEMPLATES.includes(+templateId) ?
              (section.isVisible !== "0" && section.details.content?.trim() !== "" && section.details.title1?.trim() !== "" ?
                <Process data={section.details} key={section.key} /> : null)
              :
              (section.isVisible !== "0" && section.details.content?.trim() !== "" && section.details.title1?.trim() !== "" &&
                UserSection?.filter(i => i.name === "youtube")?.[0]?.isVisible !== "0" && UserSection?.filter(i => i.name === "youtube")?.[0]?.details.link?.trim() !== "" ?
                (
                  <div className="row template-3__about-wrapper my-lg-5 my-2 mx-auto" key="sec1">
                    <div className="col-12 col-lg-6 template-3__about-content p-0">
                      <Process data={section.details} key={section.key} half />
                    </div>
                    <div className="col-12 col-lg-6 p-0">
                      <YoutubeSection data={UserSection?.filter(i => i.name === "youtube")?.[0]?.details} key={UserSection?.filter(i => i.name === "youtube")?.[0]?.key} />
                    </div>
                  </div>
                ) :
                section.isVisible !== "0" && section.details.content?.trim() !== "" && section.details.title1?.trim() !== "" &&
                  !(UserSection?.filter(i => i.name === "youtube")?.[0]?.isVisible !== "0" && UserSection?.filter(i => i.name === "youtube")?.[0]?.details.link?.trim() !== "")
                  ?
                  <div className="row template-3__about-wrapper my-lg-5 my-2 mx-auto" key="sec2">
                    <div className="col-12 col-lg-8 template-3__about-content p-0 mx-auto without-video">
                      <Process data={section.details} key={section.key} />
                    </div>
                  </div> :
                  !(section.isVisible !== "0" && section.details.content?.trim() !== "" && section.details.title1?.trim() !== "") &&
                  (UserSection?.filter(i => i.name === "youtube")?.[0]?.isVisible !== "0" && UserSection?.filter(i => i.name === "youtube")?.[0]?.details.link?.trim() !== "") &&
                  <div style={{ marginTop: 60 }} key="sec3">
                    <YoutubeSection withoutAboutData data={UserSection?.filter(i => i.name === "youtube")?.[0]?.details} key={UserSection?.filter(i => i.name === "youtube")?.[0]?.key} />
                  </div>
              ),
            twitter: section.isVisible !== "0" && [8, 9].includes(+currentPlan?.plan?.id || 0) &&
              section.details.link?.trim() !== "" && (
                <TwitterSection data={section.details} key={section.key} />
              ),
          }[section.name])
        )}

        {/* <Service />
      <Explore /> */}
        {/* {token ? <></> : <Ads />} */}

        {/* <Ads />
        <PropertiesByCities />
        <RecentProperties />
        <FeaturedPorject />
        <WhyChooseUs />
        <OurPartner /> */}
        {/* <OurPartner /> */}
    
      
        {/* {vendor === "amakkn" && <WLBanner />} */}
       
        {vendor === "amakkn" && (
          <>
            {/* <RegionsBanner /> */}
            <DownloadBanner />
            <GoToTop />
          </>
        )}
        
      </AppLayout>
    )
  );
}

export default Home_V1;
